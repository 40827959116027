.destination-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;
}

.destinations{
    width: calc(1280px - 48px);
    padding: 0px 24px;
}
.destination-heading{
    margin-top: 2.5rem;
}
.top-destination-cards{
    display: flex;
    justify-content: space-between;
    margin-top: 2.5rem;
}
.destination-card{
    width: calc(100%/4 - 24px);
}

@media screen and (max-width:1024px){
    .destination-wrapper{
        margin-bottom: 10px;
    }
    .destination-card {
        width: calc(100%/3 - 12px);
    }
    .top-destination-cards{
        margin-top: 1.5rem;
    }
    
}
@media screen and (max-width:600px) {
    .destination-wrapper{
        margin-bottom: 0px;
    }
    .destinations{
        width: 100%;
        /* padding: 0px 16px; */
        padding: 0px;
    }
    .destination-heading{
        padding: 0px 16px;
    }
    .top-destination-cards{
        /* margin-top: 1.5rem; */
        margin: 0;
        padding: 24px 10px 0px 10px; 
        overflow-x: scroll;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
    .top-destination-cards::-webkit-scrollbar {
        display: none;
    }
    .destination-card {
        min-width: calc(100%/2 - 10px);
        margin: 0px 6px;
    }
    
}