.main-toast-container-wrapper {
  height: 100%;
  /* position: relative; */ 
}

.container {
  font-size: 14px;
  position: fixed;
  z-index: 100000;
}

.buttom-right {
  top: 3rem;
  right: 0.5rem;
  animation: toast-in-right .4s;
}

.notification {
  position: relative;
  margin-bottom: 2rem;
  /* margin-bottom: 1rem; */
  border-radius: 4px;
  /* box-shadow: 0 0 10px #999; */
  color: #000;
  /* opacity: 0.9; */
  transition: .3s ease;

}

.notification:hover {
  box-shadow: 0 0 12px #1c1c1c;
  opacity: 1;
}

.toast {
  height: 36px;
  width: 284px;
  /* width: 262px; */
  color: #fff;
  padding: 20px 15px 10px 10px;
  background-color: #000;
  border: 2px solid #fbcc7a;
  /* padding-left: 36px; */
}

.container button {
  float: right;
  /* background: none; */
  border: none;
  color: #fff;
  opacity: 0.8;
  cursor: pointer;
}

.description {
  /* font-weight: 700;
    font-size: 16px;
    text-align: left; */
  margin-top: 0;
  margin-bottom: 6px;
  /* width: 300px; */
  height: 18px;
  padding: 0px 6px;
  text-align: center;
  color: #fbcc7a;
}

/* .description {
    margin: 0;
    text-align: left;
  } */

.toast-icon {
  border: 2.5px solid #fbcc7a;
  border-radius: 100%;
  width: 54px;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -28px;
  background-color: #1c1c1c;
}

#t-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
}

.toast-text {
  /* margin-left: 54px; */
  margin-left: 44px;
  margin-right: 8px;
}

.toast-del {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: gray;
  height: 66Px;
  position: absolute;
  /* right: 1.5px;
    top: 1px; */
  right: 0px;
  top: 0px;
  border-radius: 0px 2px 2px 0px;
  width: 28px;
  color: #fbcc7a;
  opacity: 1;
  font-size: 18px;
}

@keyframes toast-in-right {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

@media screen and (max-width: 600px) {
  .buttom-right {
    right: 0.2rem;
  }
}