.signup-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.signup-page{
    width: var(--content-width);
    height: 84vh;
    /* height: 80%; */
    margin: 0rem 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.signup-container-size {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: inherit;
    height: 100%;
}

.signup-left{
    width: 43%;
    background-color: #E3E8FF;
    border-radius: 20px 0px 0px 20px;
    /* height: 100%;
    padding: 2.8rem 4.8vw; */
    padding: 6rem 4.8vw;
    height: calc(100% - 12rem);
}

.signup-left-heading {
    width: 100%;
    display: flex;
    justify-content: center;
}

.signup-left-heading img {
    width: 192px;
    height: 45px;
}

.signup-carousel {
    width: 100%;
    display: flex;
    justify-content: center;
}

.signup-right {
    position: relative;
    width: 43%;
    background-image: linear-gradient(to bottom right, #373D5F, #1F1D36);
    border-radius: 0px 20px 20px 0px;
    padding: 0vw 4.8vw;
}
.signup-right-content{
    margin: 2rem 0rem;
    height: 100%;
    display: flex;
    align-items: center;
}

.main-form-step1{
    /* height: 80%; */
    /* padding: 0px 8px; */
    width: 100%;
    max-height: 30rem;
    overflow-y: scroll;
    -ms-overflow-style: none;  
    scrollbar-width: none; 
}

.main-form-step1::-webkit-scrollbar {
    display: none;
  }

/* .main-form-step1::-webkit-scrollbar {
    width: 4px;
  }

.main-form-step1::-webkit-scrollbar-track {
    background: #1F1D36; 
    border-radius:8px;
}

.main-form-step1::-webkit-scrollbar-thumb {
    background: #394277; 
    border-radius:8px;
  }
  
.main-form-step1::-webkit-scrollbar-thumb:hover {
    background: #C6D1FF; 
  } */
  
.signup-form-heading {
    color: #C6D1FF;
    /* font-size: 2.4vw; */
    font-size: 2vw;
}

.signup-form-subheading {
    color: #C6D1FF;
    /* font-size: 1.5vw; */
    font-size: 1.2vw;
    margin-bottom: 1rem;
}

.signup-form-subheading span {
    color: #E94560;
}

.signup-fields {
    margin: 8px 0px;
}

.Signup-error {
    margin-top: 2px;
    font-size: 12px;
    color: red;
}

.signup-bottom {
    display: flex;
    margin-top: 0.5rem;
}

.signup-bottom input {
    margin-right: 16px;
}
.signup-bottom input[type=checkbox] {
    accent-color: #E94560;
  }

.signup-tc {
    /* font-size: 1.2vw; */
    font-size: 0.8vw;
}

.signup-tc .tc {
    text-decoration: none;
    color: #E94560;
}

.signup-btn,
.continue-btn {
    border: 0;
    color: var(--text-color-type8);
    background-color: var(--btn-color-type1);
    padding: 6px 0px;
    border-radius: 8px;
    font-size: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0rem;
}

.singup-link {
    color: #C6D1FF;
    text-align: center;
}

.singup-link .signup-link-span {
    color: var(--text-color-type1);
    cursor: pointer;
    text-decoration: none;
}

@media screen and (orientation:portrait) {
    .signup-page{
        height: 52vh;
        /* height: 44vh; */
    }
    .signup-right-content {
        height: auto;
    }
    .main-form-step1 {
        /* height: auto; */
        padding: 0;
        height: 28rem;
    }
}
@media screen and (orientation:portrait) and (max-width:1024px) {
    .signup-page {
        /* height: auto; */
        margin: 0rem 3rem;
    }
}

@media screen and (max-width:600px) {
    .signup-wrapper {
        background-image: linear-gradient(to bottom right, #373D5F, #1F1D36);
        /* padding: 3rem 0rem; */
    }

    .signup-page {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: 4rem 1rem;
        height: 100%;
    }

    .signup-container-size {
        min-height: auto;
    }

    .signup-left {
        width: 90%;
        padding: 2rem 2.8vw;
        border-radius: 20px;
    }

    .signup-left-heading img {
        width: 150px;
    }

    .signup-right {
        width: 94%;
        border-radius: 20px;
        background-image: unset;
        padding: 0vw 2.8vw;
    }
    .main-form-step1 {
        height: auto;
        overflow: unset;
        max-height: fit-content;
    }
    .signup-form-heading {
        font-size: 26px;
    }

    .signup-form-subheading {
        font-size: 16px;
    }

    .signup-fields {
        margin: 8px 0px;
    }

    .signup-tc {
        font-size: 12px;
    }

}