.align-footer-item{
    display: flex;
    justify-content: center;
}
.footer-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: var(--Dark-Blue-Primary);
    align-items: center;
    flex-direction: column;
}
.footer{
    /* width:calc(1280px - 48px); */
    width: 90%;
    padding: 0px 24px;
    border-bottom: 1px solid #e94560;
}
.footer-body{
    width: 80%;
    /* margin: 5rem 0rem 3rem 0rem; */
    margin: 34px 0px;
    font-family: var(--family2);
}
.footer-sec{
    width: calc(100%/3 - 40px);
}
.footer-title{
    font-size: 24px;
    font-weight: var(--medium);
}
.footer-line{
    height: 3px;
    background-color: #e94560;
    border-radius: 4px;
    width: 22%;
    /* margin: 12px 0px; */
    margin: 6px 0px 24px;
}
.f-link{
    text-decoration: none;
}
.footer-item{
    margin: 2px 0px;
    cursor: pointer;
    font-size: 18px;
    color: var(--text-color-type8);
}
.footer-item:hover{
    color: var(--text-color-type1);
}

.footer-social-media{
    margin-top: 8px;
    width: 57%;
    display: flex;
    justify-content: space-between;
}
.footer-social-media a img{
    width: 30px;
    margin-top: 6px;
}
.footer-social-media a img:hover{
    filter: invert(45%) sepia(50%) saturate(5397%) hue-rotate(326deg) brightness(96%) contrast(91%);
}

.footer-social-media a .icon{
    color: var(--icon-color-type7);
    font-size: 40px;
}
.footer-social-media a .icon:hover{
    color: var(--icon-color-type1);
}
.footer-wrapper .copyright{
    margin-top: 0;
}

@media screen and (max-width:1024px) {
    .footer-body{
        width: 100%;
    }
}

@media screen and (max-width:600px) {
    .footer{
        display: none;
    }
}