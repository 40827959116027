.NotFound-wrapper{
    width: 100%;
    height: 100vh;
    /* position: absolute;
    bottom: 0px; */
}
.NotFound{
    height: 93%;
}
.NF-circles{
    position: relative;
}
.notfound-c1{
    width: 360px;
    height: 360px;
    background-color: #414040;
    border-radius: 100%;
    opacity: 0.3;
    position: absolute;
    left: 25%;
    top: 0;
}
.notfound-c2{
    width: 260px;
    height: 260px;
    background-color: #414040;
    border-radius: 100%;
    opacity: 0.3;
    position: absolute;
    left: 32%;
    top: 270px;
    z-index: -1;
}
.notfound-c3{
    width: 500px;
    height: 500px;
    background-color: #414040;
    border-radius: 100%;
    opacity: 0.3;
    position: absolute;
    right: 17%;
    top: 71px;
    z-index: -1;
}
.Not-found-messages{
    padding-top: 100px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.message1{
    display: flex;
    z-index: 1;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.message1 #m1{
    font-size: 124px;
    font-weight: 700;
}
.message1 #m2{
    font-size: 24px;
    font-weight: 600;
}
.message2{
    margin-top: 8px;
    color: #fbcc7a;
    z-index: 1;
    text-align: center;
    font-size: 18px;
}
.Not_found_copyright{
    position: absolute;
    bottom: 0;
    /* bottom: -48px; */
    width: 100%;
}
@media screen and (min-width: 600px) and (max-width: 1024px) {
    .NotFound{
        height: 95%;
    }
    .Not-found-messages {
        /* padding-top: 228px; */
        padding-top: 154px;
    }
    .notfound-c1 {
        left: 15%;
        top: 130px;
    }
    .notfound-c2 {
        left: 15%;
        top: 426px;
    }
    .notfound-c3 {
        width: 462px;
        height: 462px;
        right: 1%;
        top: 256px;
    }
    .message1 #m1{
        font-size: 100px;
    }
}
@media screen and (max-width:600px){
    .notfound-c1 {
        width: 224px;
        height: 224px;
        left: -14%;
        top: 122px;
    }
    .notfound-c2 {
        width: 160px;
        height: 160px;
        left: 13%;
        top: 298px;
    }
    .notfound-c3 {
        width: 220px;
        height: 220px;
        right: 3%;
        top: 183px;
    }
    .Not-found-messages{
        padding-top: 144px;
    }
    .message1 #m1{
        font-size: 72px;
    }
    .message1 #m2{
        font-size: 1.4em;
    }
    .message2>p{
        /* margin-top: 8px; */
        color: #fbcc7a;
        z-index: 1;
        text-align: center;
        font-size: 12px;
    }
}

