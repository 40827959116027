.align-ac-item{
    display: flex;
    justify-content: center;
    align-items: center;
}

.ac-info-card{
    background-color: var(--bg-color-type5);
    border-radius: 16px;
    padding: 24px 16px;
    flex-direction: column;
    width: 168px;
    margin: 0rem 1rem;
}
.info-card-img{
    width: 80px;
}
.ac-info-card-value{
    color: var(--text-color-type1);
    font-size: 28px;
    font-weight: var(--medium);
    margin: 4px 0px;
}
.ac-info-card-name{
    color: var(--text-color-type1);
    font-size: 20px;
    font-family: var(--family2);
}

@media screen and (max-width:1024px) {
    .ac-info-card{
        width: 136px;
    }
    .info-card-img {
        width: 54px;
    }
}

@media screen and (max-width:600px) {
    .ac-info-card{
        border-radius: 8px;
        padding: 6px 10px;
        height: fit-content;
        margin: 0px 6px;
        width: auto;
        width: calc(100%/3 - 6px);
    }
    .info-card-img{
        width: 34px;
    }
    .ac-info-card-value{
        font-size: 12px;
    }
    .ac-info-card-name{
        font-size: 12px;
    }
    /* .ac-info-cards {
        width: 80%;
    } */
}