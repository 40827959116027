.place-address-row{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 6px 0px;
}
.place-address{
    display: flex;
    align-items: center;
}
.place-location{
    color: var(--icon-color-type11);
    width: 20px;
}
.place-address span{
    font-size: 14px;
    margin-left: 2px;
    color: var(--text-color-type6);
}
.place-address-row .rating .starIcon{
    width: 18px;
}
.place-address-row .rating .rate{
    font-size: 14px;
    font-weight: normal;
    margin-left: 2px;
    color: var(--text-color-type6);
}

