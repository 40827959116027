.sf-sub-header{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 1rem 0rem;
    align-items: center;
}
.sf-sub-heading{
    display: flex;
    /* font-size: 18px; */
    font-size: 1.3vw;
}

.sf-sub-heading span{
    color: var(--icon-color-type6);
    margin-left: 0.5rem;
}

.sf-sub-heading span .filter-info-icon {
    font-size: 16px;
    cursor: pointer;
}

.search-clear-all{
    color: var(--text-color-type1);
    /* font-size: 14px; */
    font-size: 1vw;
    cursor: pointer;
}

@media screen and (max-width:1024px) {
    .sf-sub-heading {
        font-size: 16px;
    }
    .search-clear-all {
        font-size: 12px;
    }
    .sf-sub-heading span{
        display: flex;
        align-items: center;
    }
}

/* @media screen and (max-width:600px) {
    .sf-sub-heading {
        font-size: 16px;
    }
} */