.SecurityPage{
    margin: 16px 20px;
}
.S-P-input{
    background-color: #c4c4c4;
    border: 0px;
    border-radius: 3px;
    /* width: 100%; */
    padding-left: 10px;
    width: 98%;
    height: 32px;
    margin-top: 14px;
    /* margin: 14px 0px; */
    font-size: 16px;
    color: #fff;
    color: #1c1c1c;
}
.Security-error{
    margin-top: 2px;
    font-size: 12px;
    color: red;
}
.Security-Password>p{
    margin-top: 12px;
    font-size: 18px;
}
.Security-input{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.sec-qn{
    padding: 1.5% 3%;
    /* margin: 0 5%; */
    height: 36px;
    /* margin-top: 14px; */
    margin: 14px 0px;
    width: 100%;
    color: #989898;
    font-weight: 600;
    border-radius: 6px;
    font-size: 19px;
    border: 2px solid #989898;
    background-color: transparent;
}
.dropdown-arrow{
    pointer-events: none;
    width: 10%;
    /* height: 100%; */
    background-color: #989898;
    border-radius: 0 6px 6px 0;
    position: absolute;
    right: 0%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Security-Password #S-Q-input{
    background-color: #1c1c1c;
    border: 2px solid gray;
    border-radius: 5px;
    width: 96.5%;
    height: 32px;
    margin: 14px 0px;
    color: #fff;
    font-size: 16px;
    padding-left: 10px;
    /* width: 100%; */
}
.Security-Password-btn{
    display: flex;
    justify-content: center;
}
.Security-Password-btn #Updt-btn{
width: 82px;
height: 30px;
border: 0px;
border-radius: 5px;
font-size: 16px;
background-color: #66D685;
color: white;
font-weight: 900;
margin: 10px 0px;
cursor: pointer;
}
@media screen and (max-width: 1000px ){
    .sec-qn{
        font-size: 16px;
    }
    .Security-Password #S-Q-input{
        width: 96%;
    }
}
@media screen and (max-width: 600px){
    .Security-Password #S-Q-input{
        width: 96%;
        /* border-radius: 0px 2px 2px 0px; */
    }
}