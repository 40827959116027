.view-all{
    display: flex;
    margin: 40px auto;
    justify-content: center;
    align-items: center;
}

.view-all hr{
    width: 20%;
    height: 2px;
    background-color: var( --icon-color-type3);
    border-radius: 2px;
    border: none;
}

.view-all-btn{
    font-size: 16px;
    border: 2px solid var( --icon-color-type3);
    border-radius: 20px;
    padding: 8px 20px;
    margin: 0px 24px;
    color: var(--text-color-type2);
    cursor: pointer;
}