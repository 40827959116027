.review-card{
    background-color: #ffffff0D;
    min-height: 300px;
    border-radius: 10px;
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: calc(100%/3 - 8rem);
    cursor: pointer;
    transition: 500ms ease-in-out;
    margin: 0px 10px;
}

.review-card:hover{
    background-color: var(--bg-color-type5);
    /* transform: scale(1.1); */
}
.reviewer-img{
    background-color: var(--bg-color-type5);
    width: 144px;
    height: 144px;
    border-radius: 100%;
    overflow: hidden;
}
.review-card:hover .reviewer-img{
    background-color: var(--bg-color-type7) ;
}
.reviewer-img img{
    width: 100%;
    height: 100%;
    object-fit: inherit;
}
.reviewer-name{
    /* font-size: 24px; */
    font-size: 1.7vw;
    font-weight: var(--medium);
    margin: 1rem 0rem;
    font-family: var(--family2);
}
.review{
    /* font-size: 20px; */
    font-size: 1.4vw;
    font-style: italic;
    text-align: center;
    margin-bottom: 1rem;
    font-family: var(--family2);
}
.review-card:hover .review{
    color: var(--text-color-type6);
}
.review-card:hover .reviewer-name{
    color: var(--text-color-type6);
}

@media screen and (max-width:1024px) {
    .review-card{
        width: calc(100%/3 - 4rem);
        padding: 1rem;
        min-height: 236px;
    }
    .reviewer-img{
        width: 94px;
        height: 94px;
    }
    .reviewer-name{
        font-size: 16px;
        margin: 0.5rem 0rem;
    }
    .review{
        font-size: 12px;
    }
}

@media screen and (max-width:600px) {
    .review-card{
        width: calc(100%/3 - 6px);
        padding: 0.5rem;
        min-height: fit-content;
        margin: 3px;
        border-radius: 4px;
    }
    .reviewer-img{
        width: 44px;
        height: 44px;
        margin-top: 0.5rem;
    }
    .reviewer-name{
        font-size: 10px;
        margin: 3px 0px;
    }
    .review{
        font-size: 8px;
        margin-bottom: 0.4rem;
    }
}