.Abt_Details{
    width: 100%;
    height: auto;
    /* margin: 64px 0px 0px 64px; */
    margin-top: 90px;
    display: flex;
    flex-direction: row;
}
.Abt-Details-left{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 50%;
    /* margin-left: 108px; */
    margin-left: 8%;
}
.Abt-tilte{
    color: #fbcc7a;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
    letter-spacing: 2px;
}
.Abt-heading{
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 2px;
    margin-bottom: 24px;
}
.Abt-body>p{
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 22px;
    margin-bottom: 100px;
    color: #989898;
}
.Abt-circle{
    width: 260px;
    height: 250px;
    /* width: 315px;
    height: 300px; */
    background: #FBCC7A;
    border-radius: 100%;
    position: absolute;
    bottom: 0px;
    left: -158px;
}
.Abt-info-cards{
    display: flex;
    flex-direction: row;
    padding: 42px 22px;
    justify-content: space-around;
}
.Abt-info-card{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.Abt-info-card #Abt-card-details{
    color: #1c1c1c;
    font-weight: 700;
    font-size: 34px;
    margin: 0px 20px 20px;
}
.Abt-info-card #Abt-card-title{
    color: #989898;
    font-weight: bold;
}
.Abt-info-cards>hr{
    transform: rotate(180deg);
    color: #989898;
    border: 0.5px solid grey;
    margin: 0px 16px;
}
.Abt-info{
    width: 122%;
    height: 216px;
    background-color: white;
    position: relative;
    /* bottom: 12vh; */
    border-radius: 12px;
    z-index: 1;
    bottom: 52px;
}
.Abt-Details-right{
    position: relative;
    width: 50%;
    /* height: 600px; */
    overflow: hidden;
}
.Abt-Cright{
    width: 40vw;
    height: 40vw;
    /* width:560px; */
    /* height: 585px; */
    border: 2px solid #ffff;
    border-radius: 100%;
    position: absolute;
    right: -12%;
    margin-bottom: 30px;
    background-image: url("https://images.pexels.com/photos/11635371/pexels-photo-11635371.jpeg?auto=compress&cs=tinysrgb&w=600");
    background-size: cover;
    background-position: center;
}

@media screen and (max-width: 1000px) {
    .Abt-circle{
        width: 299px;
        height: 245px
    }
    .Abt-info-cards{
        padding: 34px 22px;
    }
    .Abt-info-card #Abt-card-details{
        font-weight: bolder;
        font-size: 18px;
        margin: 0px 14px 14px;
    }
    .Abt-info-cards>hr{
        margin: 12px 9px;
    }
    .Abt-info{
        height: 176px;
        /* bottom: 321px; */
    }
    .Abt-Cright{
        height: 60%;
        width: 105%;
    }
}
@media screen and (max-width: 600px) {
    .Abt_Details{
        flex-direction: column-reverse;
    }
    .Abt-Details-right{
        /* position: unset; */
        width: 100%;
        height: 330px;
    }
    .Abt-Cright{
        /* right: 0px; */
        width: 90%;
        height: 300px;
    }
    .Abt-Details-left{
        width: 90%;
        margin: 0% 5%;
    }
    .Abt-heading{
        font-size: 28px;
        letter-spacing: normal;
    }
    .Abt-info{
        width: auto;
        height: 158px;
        bottom: 5vh;
    }
    .Abt-info-card #Abt-card-details{
        font-size: 11px;
        margin: 0px 14px 7px;
    }
    .Abt-info-card #Abt-card-title{
        font-size: 10px;
    }
}
