.card {
    /* width: 281px; */
    /* width: calc(100%/4 - 10px); */
    height: 360px;
    background-color: var(--bg-color-type4);
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    transition: all 0.8s ease;
}

.card:hover {
    transform: scale(1.08);
    transition: all 0.5s ease;
    /* transition-duration: 1s; */
}

.trend-img {
    width: 111%;
    height: 82%;
    border: 2px solid #fbcc7a;
    border-radius: 186px;
    position: absolute;
    right: 0px;
    left: -35px;
    top: -81px;
}

.trend-wish .Toastify {
    display: none;
}

.trend-wish {
    position: absolute;
    bottom: 16px;
    right: 16px;
    background-color: #ffffff80;
    width: 50px;
    height: 50px;
    border-radius: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.trend-shade1 {
    width: 84%;
    height: 60%;
    background: #1c1c1c80;
    border-radius: 132px;
    position: absolute;
    bottom: -12px;
    left: -92px
}

.trend-shade2 {
    width: 115%;
    height: 74%;
    background: #1c1c1c80;
    border-radius: 159px;
    position: absolute;
    /* bottom: -24px; */
    left: -7px;
    bottom: -188px;
}

.trend-details {
    position: absolute;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.trend-place-info>p {
    display: flex;
    justify-content: center;
    font-size: 30px;
    font-weight: var(--medium);
}

.trend-place-address {
    display: flex;
    justify-content: center;
    margin: 3px 0px 12px 0px;
    color: var(--text-color-type7);
}

.trend-rate {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    background-color: var(--btn-color-type3);
    padding: 10px;
    border-radius: 24px;
    margin-bottom: 22px;
    width: 80%;
    font-size: 13px;
    cursor: pointer;
    transition: all 0.8s ease;
}

.trend-rate #trending-icon {
    color: var(--icon-color-type2);
    margin-right: 4px;
    transition: all 0.8s ease;
}

.card:hover #tr-rate {
    background-color: var(--btn-color-type2);
    transition: all 0.8s ease;
}

.card:hover #tr-rate #trending-icon {
    transition: all 0.8s ease;
    opacity: 0;
}

.card:hover #tr-rate::after {
    content: "Learn More";
    text-align: center;
    width: 100%;
    position: absolute;
    font-weight: 500px;
    font-size: 16px;
    z-index: 2;
    /* transition: all 0.1s ease; */
}

.trend-rate>p {
    color: var(--text-color-type3);
}

@media screen and (max-width: 1024px) {
    .card{
        height: 336px;
    }
    .card:hover {
        transition: none;
        transform: none;
    }
    .trend-img {
        left: -41px;
        top: -51px;   
        width: 115%;
        height: 73%;
    }
    .trend-wish{
        right: 6px;
    }
    .trend-place-info>p{
        font-size: 24px;
    }
    .trend-rate{
        font-size: 10px;
    }
    .trend-shade2{
        bottom: -148px;
    }

}

@media screen and (max-width: 600px) {
    .card {
        height: 260px;
        border-radius: 4px;
    }
    .card:hover {
        transition: none;
        transform: none;
    }
    .trend-img {
        width: 109%;
        height: 78%;
        left: -24px;
        top: -55px;
    }
    .trend-wish{
        bottom: 0px;
        right: 14px;
        width: 44px;
        height: 44px;
    }
    .trend-shade1{
        bottom: -4px;
        left: -70px;
    }
    .trend-shade2{
        bottom: -96px;
    }
    .trend-place-info>p{
        font-size: 16px;
    }
    .trend-place-address{
        font-size: 12px;
        margin: 0px 0px 8px 0px;
    }
    .trend-rate{
        margin-bottom: 16px;
        padding: 6px 8px;
        font-size: 8px;
    }
    .trend-rate #trending-icon{
        font-size: 16px;
    }
}