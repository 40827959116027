.places #container-title{
    font-size: 22px;
    margin: 54px 0px 16px 0px;
}

.whislistCards{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
}
.No-place{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 70px 0px;
}
.whislistCards #noti{
    display: flex;
    justify-content: center;
    width: 100%;
    color: #fbcc7a;
}
.w-card{
    margin: 0px 24px 18px 0px;
    margin-bottom: 18px;
}

@media screen and (max-width: 600px) {
    .w-card{
        margin: 12px 0px;
    }
}
