/* step2 from style */
.signup-back-arrow .back-arrow-iocn{
    color: #C6D1FF;
    cursor: pointer;
    position: absolute;
    top:25px;

}
.signup-back-arrow .back-arrow-iocn:hover{
     color: #fff;
}
.step2-heading {
    text-align: center;
    margin-bottom: 0.5rem;
}

.step2-sub-heading {
    text-align: center;
    margin-bottom: 0;
}

.step2-celebration {
    display: flex;
    justify-content: center;
    margin: 0.5rem 0rem;
}

.step2-celebration img {
    width: 160px;
}

.step2-sub-heading2 {
    color: #C6D1FF;
    /* font-size: 24px; */
    /* font-size: 1.75vw; */
    font-size: 1.45vw;
    font-weight: var(--medium);
    margin: 0.5rem 0rem;
}

.main-form-step2{
    height: 28%;
    /* height: auto; */
    overflow-y: scroll;
    /* padding: 0px 8px; */
    -ms-overflow-style: none;  
    scrollbar-width: none; 
}
.main-form-step2::-webkit-scrollbar {
    display: none;
}
/* .main-form-step2::-webkit-scrollbar {
    width: 4px;
  }
.main-form-step2::-webkit-scrollbar-track {
    background: #1F1D36; 
    border-radius:8px;
}

.main-form-step2::-webkit-scrollbar-thumb {
    background: #394277; 
    border-radius:8px;
  }
.main-form-step2::-webkit-scrollbar-thumb:hover {
    background: #C6D1FF; 
  } */
  
.gender {
    margin-bottom: 0.75rem;
}

.label {
    color: #C6D1FF;
    margin-bottom: 0.5rem;
    font-size: 14px;
}

.gender-options {
    display: flex;
    justify-content: space-between;
}

.gender-active {
    background-color: #E94560;
    padding: 0.25rem 0.5rem;
    /* border-radius: 13px; */
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36%;
    /* font-size: 20px; */
    font-size: 16px;
    font-weight: var(--medium);
    margin-right: 1rem;
    cursor: pointer;
}

.gender-inactive {
    border: 3px solid #E94560;
    border-radius: 6px;
    color: #E94560;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36%;
    /* font-size: 20px; */
    font-size: 16px;
    font-weight: var(--medium);
    padding: 0.25rem 0.5rem;
    cursor: pointer;
}

.dob input {
    padding: 0.35rem 1rem;
    width: calc(100% - 2rem);
    border-radius: 6px;
    background-color: #E3E8FF;
    /* font-size: 20px; */
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
}
.dob .error{
    border: 2px solid red;
}

.dob input::placeholder {
    color: #C6D1FF;
}
@media screen and (max-width:600px) {
    .signup-back-arrow .back-arrow-iocn{
        top: -24px;
    }
    /* step-2 */
    .step2-heading{
        font-size: 34px;
    }
    .step2-sub-heading{
        font-size: 24px;
    }
    .step2-celebration img {
        width: auto;
    }
    .step2-sub-heading2 {
        font-size: 24px;
    }
    .main-form-step2 {
        height: auto;
    }
    .label{
        font-size: 18px;
    }
    .gender,.dob{
        margin: 1rem 0rem;
    }
}