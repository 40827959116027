.input-field{
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
}
.input-field input{
    border: 1px solid #0a97c6;
    /* outline: none; */
    background-color: #E3E8FF;
    /* padding: 10px 8px; */
    /* font-size: 18px; */
    /* border-radius: 8px; */
    padding: 6px 8px;
    font-size: 16px;
    border-radius: 6px;
    border: none;
    padding-left: 44px;
    color: #1F1D36;
    width: 100%;
}
.input-field input:focus{
    outline: none;
    border: 1px solid #0d90bb;
}

.input-field .error{
    border: 2px solid red;
}

.input-field input::placeholder{
    color: #1F1D36;
    font-size: 16px;
    font-family: var(--family2);
    /* padding-left: 32px; */
}

.input-field .input-field-icon{
    position: absolute;
    left: 8px;
    color: var(--icon-color-type6);
    font-size: 24px;
    display: flex;
}
.password-eye{
    cursor: pointer;
    position: absolute;
    right: 10px;
    color: var(--icon-color-type1);
}

@media screen and (max-width:600px) {
    .input-field input{
        border-radius: 6px;
    }
}