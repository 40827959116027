.loading-bg {
  background-color: var(--Dark-Blue-Primary);
  width: 223px;
  height: 223px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 45px;
  margin: 8rem 0rem;
}
.loading-bg .spinner {
  /* width: 78px; */
  width: 80px !important;
  height: 80px !important;
}
/* Change the running color */
.loading-bg .MuiCircularProgress-root .MuiCircularProgress-circle {
  stroke: var(--Accent);  /* Running color */
}

/* Change the path color (background color) */
.MuiCircularProgress-circleStatic {
  color: #e0e0e0 !important; /* Path color */
}

@media screen and (max-width: 600px) {
  .loading-bg {
    width: 180px;
    height: 180px;
    border-radius: 40px;
  }
  .loading-bg .spinner {
    /* width: 78px; */
    width: 70px !important;
    height: 70px !important;
  }
}
