.place-img{
    width: 36%;
    /* height: 144px; */
    position: relative;
    overflow: hidden;
}
.place-tag{
    position: absolute;
    width: 72%;
    padding: 2px 10px;
    font-size: 15px;
    text-align: center;
    transform: rotate(-36deg);
    left: -40px;
    top: 14px;
}
.place-img img{
   border-radius: 8px;
   object-fit: cover;
   width: 100%;
   height: 100%;
   aspect-ratio: 1/0.9;
}
.single-color{
    background-color: var(--bg-color-type6);
}
.package-color{
    background-color:var(--bg-color-type8);
}
