.week-row{
    display: flex;
    margin-bottom: 6px;
}
.wr-calendar{
    color: var(--icon-color-type11);
    width: 20px;
}
.week-days{
    margin-left: 2px;
    display: flex;
    width: 36%;
    justify-content: space-around;
    font-size: 15px;
    align-items: center;
}

.inactive-weekDay{
    color: var(--text-color-type5);
}
@media screen and (max-width:1024px){
  .week-row .wr-calendar{
        font-size: 18px;
    }
}
@media screen and (max-width:600px){
    .calendar-with-time{
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .calendar-with-time .place-timing{
        display: flex;
    }
    .calendar{
        display: flex;
        width: 44%;
    }
    .calendar .week-days{
        width: 80%;
    }
    .calendar-with-time  .calendar .wr-calendar{
        font-size: 18px;
    }
}