.place-name-header{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.place-name-header span{
    color: var(--text-color-type1);
    font-size: 18px;
}

.place-name-header .redbtn-type2{
    width: 24%;
    padding: 4px 8px;
    background-color: var(--btn-color-type2);
}
.free {
    width: 24%;
}
.free .redbtn-type2{
    width: 100%;
    background-color: var(--btn-color-type5);
}

@media screen and (max-width:600px){
    .free {
        width: 24%;
    }
    .free .redbtn-type2{
        padding: 8px;
    }
}