.cart-wrapper {
    min-height: 100vh;
    min-width: 100%;
    max-width: 100%;
    overflow-x: hidden;
  }
  
  .cart-body {
    padding: 17vh 2% 3vh;
    width: 96%;
    height: 90vh;
    display: flex;
    justify-content: space-between;
  }
  
  .cart-center-flex {
    max-height: 75vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
  }
  
  .cart-empty-img {
    height: 30%;
    margin-top: 9%;
    margin-bottom: 5px;
  }
  
  .cart-emty-msg {
    color: #fbcc7a;
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  
  .cart-empty-text {
    text-decoration: none;
    font-size: 18px;
    font-style: italic;
    color: #74ebd5;
  }
  
  .cart-empty-button {
    border-radius: 4px;
    border: 2px solid #fbcc7a;
    margin-top: 10px;
    background-color: #fbcc7a;
    padding: 6px 3%;
    text-decoration: none;
    font-size: 20px;
    font-weight: 700;
    color: #040404;
    transition: 0.8s ease-in-out;
  }
  
  .cart-empty-button:hover {
    background-color: #040404;
    color: #fbcc7a;
  }
  
  .cart-cards {
    max-width: 78%;
    min-width: 78%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .cart-sidebar {
    max-width: 21%;
    min-width: 21%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .cart-myPlan {
    padding: 2vh 0 2vh 3%;
    height: 43.4%;
    width: 96%;
    background-color: #414040;
    border-radius: 7px;
  }
  
  .cart-recommended {
    padding: 2vh 0 2vh 3%;
    height: 43.4%;
    width: 96%;
    background-color: #414040;
    border-radius: 7px;
  }
  
  .cart-title {
    margin-bottom: 2vh;
  }
  
  .cart-slider {
    display: flex;
    overflow-x: scroll;
    scrollbar-width: none;
  }
  
  .cart-slider::-webkit-scrollbar {
    display: none;
  }
  
  .cart-details {
    padding: 0 5%;
    height: 89.55%;
    width: 90%;
    background-color: #414040;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
  }
  
  .cart-Key {
    margin-top: 18px;
    color: #989898;
    font-weight: 800;
  }
  
  #cart-Start-Date {
    padding: 2% 2%;
    font-size: 16px;
    font-weight: 600;
  }
  
  #cart-end-Date {
    padding: 2% 2%;
    font-size: 16px;
    font-weight: 600;
  }
  
  .cart-Date {
    margin-top: 8px;
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 90%;
  }
  
  .cart-date-icon {
    padding-left: 5%;
    position: absolute;
    height: 100%;
    width: 20%;
    background-color: #414040;
    right: 0;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .cart-peoples {
    margin-top: 10px;
    display: flex;
    align-items: center;
  }
  
  .cart-minus {
    width: 25px;
    height: 25px;
    border: 2px solid #c4c4c4;
    background-color: rgba(196, 196, 196, 0.35);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .cart-NOP {
    margin: 0 8%;
    font-size: 25px;
  }
  
  .cart-add {
    width: 25px;
    height: 25px;
    border: 2px solid #fbcc7a;
    background-color: rgba(251, 204, 122, 0.35);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .cart-quotes {
    padding: 40px 3% 0;
    text-align: center;
    color: #989898;
  }
  
  .cart-writer {
    padding: 5px 3% 0;
    margin-top: 5px;
    display: flex;
    justify-content: flex-end;
    font-size: 13px;
    color: #989898;
  }
  
  .cart-planButton {
    border-radius: 3px;
    display: flex;
    justify-content: center;
    padding: 1vh 0;
    width: 100%;
    background-color: #fbcc7a;
    font-size: 4vh;
    font-weight: bolder;
    color: black;
    cursor: pointer;
  }
  .cart-plan-link {
    text-decoration: none;
  }
  
  @media  screen and (max-width:1000px) {
    .cart-body {
      padding: 12vh 2% 3vh;
    }
    .cart-cards {
      justify-content: unset;
    }
    .cart-myPlan {
      height: 32vh;
      margin-bottom: 10%;
    }
    .cart-recommended{
      height: 32vh;
    }
    .cart-sidebar{
      justify-content: unset;
    }
    .cart-details {
      height: 77.55%;
      margin-bottom: 16px;
    }
    .cart-planButton {
      font-size: 3vh;
    }
  }
  
  @media screen and (max-width: 600px) {
    .cart-body {
      padding: 10vh 0 3vh;
      width: 100%;
      height: unset;
      flex-direction: column;
    }
  
    .cart-cards {
      max-width: 100%;
      min-width: 100%;
    }
  
    .cart-sidebar {
      margin: 5vh auto 0;
      max-width: 95%;
      min-width: 95%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  
    .cart-myPlan {
      padding: 2vh 0 2vh 3%;
      height: 43.4%;
      width: 96%;
      background-color: unset;
      border-radius: 7px;
      margin-bottom: 20px;
    }
  
    .cart-recommended {
      padding: 2vh 0 2vh 3%;
      height: 43.4%;
      width: 96%;
  
      background: #74ebd5;
      background: -webkit-linear-gradient(to right, #74ebd5, #acb6e5);
      background: linear-gradient(to right, #74ebd5, #acb6e5);
      border-radius: 0px;
    }
  
    .cart-details {
      margin-bottom: 3vh;
      padding: 0 8% 5vh;
      width: 84%;
      background-color: #414040;
      border-radius: 7px;
      display: flex;
      flex-direction: column;
    }
  
    .cart-Key {
      margin-top: 25px;
      font-size: 18px;
      color: #989898;
      font-weight: 800;
    }
  
    #cart-Start-Date {
      min-width: 70%;
    }
  
    #cart-end-Date {
      min-width: 70%;
    }
  
    .cart-Date {
      margin: 15px auto 0;
      max-width: 90%;
      background-color: #acb6e5;
    }
  
    .cart-date-icon {
      right: -5%;
    }
  
    .cart-peoples {
      justify-content: center;
    }
  
    .cart-planButton {
      border-radius: 3px;
      display: flex;
      justify-content: center;
      padding: 1vh 0;
      width: 100%;
      background-color: #fbcc7a;
      font-size: 3vh;
      font-weight: bolder;
      color: black;
      cursor: pointer;
    }
  
    .cart-empty-img {
      width: 40%;
      height: auto;
      margin-top: 35%;
      margin-bottom: 5px;
    }
  
    .cart-emty-msg {
      font-size: 21px;
    }
  
    .cart-empty-link {
      font-size: 15px;
    }
  
    .cart-empty-button {
      border-radius: 2px;
      margin-top: 8px;
      padding: 6px 10%;
      font-size: 18px;
    }
  }
  
  