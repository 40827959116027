.promoted-card {
    /* width: 13rem; */
    width: 14.9vw;
    height: 16rem;
    background-color: #fff;
    border-radius: 0px 24px 0px;
    padding: 12px;
    margin: 0rem 2rem;
    /* margin: 0% 20%; */
  }
  .p-card-image {
    width: auto;
    height: 12rem;
    border-radius: 8px;
    box-shadow: 4px 5px 9px grey;
    position: relative;
  }
  .pcard-rating {
    border-radius: 18px;
    background-color: #1c1c1c;
    opacity: 0.5;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    position: absolute;
    right: 8px;
    top: 10px;
  }
  .Book-Now-btn {
    border: 0;
    background-color: #414040;
    color: #fbcc7a;
    border-radius: 4px;
    font-size: 1rem;
    padding: 10px;
    font-weight: 600;
    position: absolute;
    bottom: 8px;
    right: 8px;
  }
  .pcard-details {
    display: flex;
    justify-content: space-between;
    margin-top: 14px;
    align-items: center;
  }
  .pcard-name {
    color: #414040;
    font-size: 1.2rem;
    font-weight: 600;
  }
  .pcard-address {
    color: #989898;
    font-size: 0.9rem;
    font-weight: 600;
    /* margin-left: 2px; */
    margin-top: 2px;
  }
  .unknown-icon {
    width: 40px;
    height: 40px;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    border: 3px solid #ffcc7a;
    color: #fbcc7a;
    font-size: 16px;
  }
  @media screen and (max-width: 1024px) {
    .promoted-card {
      width: 11rem;
      /* width: 16.9vh; */
      height: 15rem;
      margin: 0rem 1rem;
    }
    .p-card-image {
      height: 11rem;
    }
    .package-promoted-cards {
      width: 64%;
    }
    .pcard-name {
      font-size: 1rem;
    }
    .unknown-icon {
      width: 36px;
      height: 36px;
      font-size: 16px;
    }
  }
  @media screen and (max-width: 600px) {
    .promoted-card {
      width: 13rem;
      height: 15rem;
      margin: 0rem 2rem;
    }
    .package-promoted-cards {
      width: auto;
      margin-bottom: 2rem;
    }
    .pcard-name {
      font-size: 1.3rem;
    }
    .slick-slide {
      width: 280px;
    }
  }
  