.login-image-card{
    height: 100%;
}
.login-image-card img{
    /* width: 124px; */
    width: 8.8vw;
    /* height: 80vh; */
    object-fit: cover;
    object-position: center;
    border-radius: 20px;
    height: 100%;
}
@media screen and (max-width:1024px){
    .login-image-card img{
        border-radius: 12px;
    }
}
@media screen and (max-width:600px){
    .login-image-card img{
        border-radius: 9.5px;
        width: 90%;
        /* width: 60px; */
        /* width: 14.8vw;
        padding: 0vw 4.8vw; */
    }
}
