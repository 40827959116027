.blog-input-wrapper{
    width: 100%;
    position: relative;
}

.blog-input-wrapper .blog-search-icon{
    width: 30px;
    height: 30px;
    position: absolute;
    color: #ffffff80;
    top: 10px;
    bottom: 0;
    left: 15px;
    text-align: center;
}
.search-input{
    border-radius: 10px;
    border: 1px solid #ffffff33;
    background-color: var(--Dark-Blue-Primary);
    color: #ffffff80;
    padding: 12px 12px 12px 52px;
    width: 100%;
    font-size: 18px;
    outline: none;
}

@media screen and (max-width:600px){
    .blog-input-wrapper .blog-search-icon{
        left: 28px;
    }
}