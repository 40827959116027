.align-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.navbar-wrapper {
  background-color: var(--bg-color-type3-t);
  width: 100%;
  top: 0;
  transition: 0.3s ease-in-out;
  position: fixed;
  display: flex;
  justify-content: center;
  z-index: 99;
}

.navbar-wrapper .container {
  position: absolute;
}
.navbar {
  padding: 4px 24px;
  position: -webkit-sticky;
  width: var(--content-width);
}

.navscrolled {
  background: var(--bg-color-type3);
}
.navbar-logo {
  padding-top: 8px;
}
.navbar-logo img {
  cursor: pointer;
  /* width: 180px; */
  height: 40px;
}

.nav-item .cart-icon {
  margin-left: 6px;
  font-size: 20px;
}

.login-nav-btn {
  background: var(--bg-color-type6);
  padding: 4px 16px;
  border-radius: 20px;
  padding-bottom: 4px;
}

.mob-menu {
  display: none;
}
.navbar-mobile {
  display: none;
}
@media only screen and (max-width: 700px) {
  .navbar-wrapper {
    flex-direction: column;
    background-color: var(--bg-color-type3-t2);
    justify-content: flex-start;
  }
  .navbar-wr-active {
    height: 100vh;
    transition: all 0.5 ease-in-out;
  }
  .navbar {
    padding: 12px 16px;
    width: auto;
  }
  .navbar-content {
    display: none;
  }
  .navbar-mobile {
    display: flex;
  }
  .mob-menu {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
  .navbar-logo img {
    width: 10rem;
    /* display: none; */
  }
  .nav-log-disable {
    display: none;
  }
  .mob-menu img {
    width: 32px;
    filter: invert(46%) sepia(96%) saturate(3518%) hue-rotate(325deg)
      brightness(94%) contrast(94%);
  }
  .navbar-content-mobile {
    flex-direction: column;
    margin-top: 4rem;
    width: 100%;
  }
  .nav-item .logout-btn {
    background-color: unset;
    padding: 0;
    /* color: #e94560; */
  }
  .nav-item .cart-icon {
    font-size: 24px;
    margin-left: 8px;
  }
  .login-nav-btn {
    background-color: unset;
  }
  .logout-line {
    margin: 0.5rem 0rem;
    height: 1px;
    background-color: #e94560;
    width: 90%;
  }
}
