.category-type-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.3rem 0.5rem;
    border-radius: 32px;
    font-size: 16px;
    border: 0;
    background-color: var(--btn-color-type7);
    cursor: pointer;
    margin: 6px 10px;
}
.category-type-btn span{ 
    display: flex;
}

.category-type-btn span .cat-icon-active{
    width: 20px;
    color: var(--icon-color-type2);
    margin-right: 5px;
}

.category-type-btn span .cat-icon-inactive{
    width: 20px;
    color: var(--icon-color-type4);
    margin-right: 5px;
}
.cat-not-active{
    background-color: var(--btn-color-type8);
}

#cat-title{
        height: 100%;
        display: flex;
        align-items: center;
        padding-top: 3px;
        font-weight: 300;
        font-family: 'Overpass';
        padding-right: 4px;
        font-size: 1.15vw;
}

@media screen and (max-width:1024px){
    .category-type-btn {
        margin: 4px;
        padding: 0.2rem 0.3rem;
    }
    .category-type-btn span .cat-icon-active, .category-type-btn span .cat-icon-inactive {
        font-size: 16px;
        margin-right: 0;
    }
}
@media screen and (max-width:600px){
    #cat-title{
        font-size: 14px;
    }
}