.align-item {
  display: flex;
  align-items: center;
}
.top-place-card {
  width: 100%;
  /* height: 332px; */
  height: 360px;
  display: flex;
  flex-direction: column;
  position: relative;
  transition: all 0.5s ease;
}
.top-place-card:hover {
  transform: scale(1.08);
  transition: all 0.4s ease;
  /* transition-duration: 1s; */
}
.tpc-img {
  width: 100%;
  height: 65%;
  border-radius: 15px 15px 0px 0px;
  object-fit: cover;
}
.top-place-card .whish_list {
  position: absolute;
  right: 8px;
  top: 12px;
}
.tpc-details {
  background-color: var(--bg-color-type5);
  color: var(--text-color-type4);
  border-radius: 0px 0px 15px 15px;
  /* padding: 0.6rem; */
  padding: 0.6rem 0.9rem;
}
.tpc-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tpc-title {
  font-size: 20px;
  font-weight: var(--medium);
  color: var(--text-color-type9);
}

.tpc-address,
.tpc-locations {
  margin-top: 4px;
}

.tpc-address span {
  font-size: 15px;
  font-family: var(--family2);
  margin-left: 12px;
}

.tpc-locations span {
  font-size: 15px;
  margin-left: 6px;
  font-family: var(--family2);
}

.tpc-btns {
  display: flex;
  justify-content: space-between;
  margin: 0.6rem 0rem;
}
.tpc-btns .redbtn-with-border {
  margin-left: 8px;
  width: 90%;
  font-size: 1vw;
}
.tpc-btns .redbtn-type2 {
  margin-right: 8px;
  width: 90%;
  font-size: 1vw;
  padding: 4px 12px;
}

@media screen and (max-width: 1024px) {
  .top-place-card {
    height: 100%;
    /* height: 336px; */
  }
  .top-place-card:hover {
    transform: none;
    transition: none;
    /* transition-duration: 1s; */
  }
  .tpc-btns .redbtn-with-border {
    padding: 4px 8px;
    font-size: 12px;
  }
  .tpc-btns .redbtn-type2 {
    padding: 4px 8px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 600px) {
  .top-place-card {
    /* min-height: 224px; */
    height: 244px;
  }
  .top-place-card:hover {
    transform: none;
    transition: none;
    /* transition-duration: 1s; */
  }
  .tpc-img {
    border-radius: 8px 8px 0px 0px;
    height: 65%;
  }
  .tpc-details {
    padding: 0.3rem 0.45rem;
    border-radius: 0px 0px 8px 8px;
  }
  .tpc-title {
    font-size: 12px;
  }
  .tpc-address img {
    width: 12px;
  }
  .tpc-locations img {
    width: 12px;
  }
  .tpc-address span {
    font-size: 8px;
    margin-left: 6px;
  }
  .tpc-address,
  .tpc-locations {
    margin-top: 0px;
  }
  .tpc-btns {
    margin: 0.3rem 0rem;
  }
  .tpc-btns .redbtn-type2 {
    padding: 2px 6px;
    border-radius: 8px;
    font-size: 8px;
    margin-right: 2px;
  }
  .tpc-btns .redbtn-with-border {
    padding: 2px 6px;
    border-radius: 8px;
    font-size: 8px;
    margin-left: 2px;
  }
}
