.download-app-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: var(--bg-color-type3);
    margin-top: 6rem;
    max-height: 600px;
}

.download-app{
    width: var(--content-width);
    padding: 0px 24px;
}
.download-app-body{
    margin-top: 4rem;
    display: flex;
}
.download-app-left{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.download-app-left .description-heading{
    font-size: 32px;
    margin-bottom: 2rem;
}
.d-app-description{
    margin-bottom: 4rem;
    font-size: 18px;
}

.download-btns{
    display: flex;
    width: 85%;
    justify-content: space-between;
    margin-bottom: 4rem;
}
.download-btns a{
    text-decoration: none;
}
.download-btns .download-app-btn {
    /* padding: 2px 24px; */
    /* width: 15rem; */
    width: 18vw;
}
.download-app-right{
    width: 49%;
}
.d-images{
    position: relative;
    display: flex;
    width: 100%;
    height: 400px;

}
.download-app-img1{
    position: absolute;
    right: -5px;
    height: 112%;
    bottom: 0;
}

.download-app-img2{
    height: 84%;
    position: absolute;
    left: 64px;
    z-index: 1;
    bottom: 0;
}

@media screen and (max-width:1024px) {
    .download-app-wrapper{
        margin-top: 3rem;
    }
    .download-app-body{
        margin-top: 1rem;
    }
    .download-app-left{
        width: 55%;
    }
    .download-app-left .description-heading {
        margin-bottom: 1.5rem;
    }
    .d-app-description{
        margin-bottom: 2rem;
    }
    .download-btns .download-app-btn {
        width: 10rem;
        padding: 4px 8px;
    }
    .download-app-right {
        width: 45%;
    }

    .download-app-img1{
        right: -24px;
        height: 96%;
    }
    .download-app-img2{
        height: 60%;
        left: -20px;
    }
}
@media screen and (max-width:600px) {
    .download-app-wrapper{
        margin-top: 2rem;
        max-height: auto;
    }
    .download-app{
        width: 100%;
        padding: 0px 16px;
    }
    .download-app-body {
        margin-top: 1.5rem;
        padding: 0px 14px;
    }
    .download-btns{
        width: 100%;
        margin-bottom: 1.5rem;
        justify-content: space-around;
    }
    .download-btns .download-app-btn {
        width: 5rem;
    }
    .download-app-left {
        width: 56%;
        margin-top: 0.5rem;
        height: fit-content;
    }
    .download-app-left .description-heading{
        font-size: 14px;
        margin-bottom: 0.5rem;
    }
    .d-app-description {
        margin-bottom: 1rem;
        font-size: 9px;
    }
    .download-app-right {
        width: 44%;
    }
    .d-images{
        height: 100%;
    }
    .download-app-img1{
        height: 100%;
        /* left: 22px; */
        left: 18px;
    }
    .download-app-img2{
        height: 90px;
        left: 10px;
    }
}