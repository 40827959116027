.nav-item {
    display: flex;
    align-items: flex-end;
    cursor: pointer;
    font-size: 16px;
    font-weight: var(--regular);
    margin: 0px 12px;
    text-decoration: none;
    color: var(--text-color-type8);
  }
  .nav-item .cart-icon {
    margin-left: 6px;
    font-size: 20px;
  }
  
  .nav-item-active {
    border-bottom: 3.5px solid #e94560;
    width: 50%;
    margin-left: 14px;
    border-radius: 12px;
    margin-top: 2px;
  }

  .hover-underline-animation {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .hover-underline-animation::after {
    text-align: center;
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: var(--bg-color-type6);
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  
  .hover-underline-animation:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
  
  @media only screen and (max-width: 700px) {
    .nav-item {
      font-weight: var(--light);
      font-size: 24px;
      padding: 4px 0px;
    }
    .nav-item-active {
      font-weight: var(--light);
      background-color: var(--bg-color-type6);
      width: 75%;
      border: 0;
      border-radius: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  