.cart-card{
    min-width: 180px;
    max-width: 180px;
    /* min-width: 18.5%;
    max-width: 18.5%; */
    height: 32vh;
    margin-right: 3%;
    border-radius: 8px;
    position: relative;
}

.cateCard-Lg{
    position: absolute;
    bottom: 0;
    padding: 10% 5% 0;
    width: 90%;
    height: 22%;
    border-radius: 0 0 8px 8px;
    background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.0));
}

.cartCard-title{
    font-size: 20px;
    color: white;
}

.cartCard-location{
    font-size: 12px;
    color: #989898;
}

.cateCard-icon{
    position: absolute;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 8px;
    top: 8px;
    cursor: pointer;
}

@media screen and (max-width:1000px){
    .cart-card{
        height: 26vh;
        min-width: 166px;
    }
}

@media screen and (max-width: 600px) {    
    .cart-card{
        min-width: 45%;
        max-width: 45%;
        height: 30vh;
        border-radius: 6px;
    }

    .cart-mob-border{
        border-top: 2px solid white;
        border-left: 2px solid white;
        border-right: 2px solid white;
        border-bottom: 3px solid white;
    }

    .cateCard-Lg{
        padding: 10% 3% 0;
        width: 94%;
        border-radius: 0 0 6px 6px;
    }
    
    .cartCard-title{
        font-size: 18px;
    }
    
    .cartCard-location{
        font-size: 10px;
    }
}

