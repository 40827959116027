.semicircle{
    width: 76%;
    height: 50%;
    display: inline-block;
}
.progress_bar{
    height: 256px;
    /* width: 25%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #414040;
    border-radius: 8px;
    position: relative;
    margin: 1% 1%;
}
.progress_bar_name{
    padding: 5px;
    text-align: center;
    width: 47%;
    font-size: 17px;
}

.info{
    height: 30px;
    width: 30px;
    border-radius:100% ;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 11px;
    bottom: 11px;
    color: #fff;
    

}
@media only screen and (max-width: 1000px) {
    .progress_bar{
        height: 160px;
    }
    .progress_bar_name{
        padding: 3px;
        font-size: 14px;
    }
    .info {
        height: 24px;
        width: 24px;
    }
}
@media only screen and (max-width: 600px) {
    .progress_bar{
        height: 180px;
        width: 100%;
        margin: 2% 1%;

    }
    .semicircle{
        width: 80%;
        height: 45%;
    }
    .info{
        height: 20px;
        width: 20px;
    }
    .progress_bar_name span{
        font-size: 13px;
    }
}