.copy-link-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.copy-link-overlay-screen {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
}
.popup {
  background-color: var(--bg-color-type5);
  color: var(--text-color-type9);
  padding: 24px 40px;
  border-radius: 10px;
  text-align: center;
  z-index: 999;
  min-width: 32%;
}
.popup p {
  text-align: left;
  color: var(--text-color-type5);
}

.popup-tp {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  border-bottom: 2px solid var(--bg-color-type9);
  padding-bottom: 14px;
}

.popup-tp h2{
  font-weight: 500;
}
.popup-tp button {
  background-color: var(--bg-color-type9);
  width: 100%;
  border: none;
  width: 32px;
  border-radius: 100%;
  height: 32px;
  font-size: 28px;
  cursor: pointer;
  color: var(--icon-color-type1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.copy-link-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  width: 100%;
}
.copy-link-container input {
  outline: none;
  border: 1px solid #ccc;
  padding: 8px;
  font-size: 16px;
  border-radius: 4px;
  width: calc(100% - 16px);
  margin: 12px 0px;
}

.copy-button {
  background: var(--btn-color-type1);
  color: white;
  border: none;
  padding: 8px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}

.cpy-btn {
  padding-left: 24px;
}

.cpy-btn .cpy-icon {
  color: var(--icon-color-type5);
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .popup{
    padding: 24px 16px;
  }
  .popup-tp h2{
    font-size: 20px;
    font-weight: 500;
  }
}
