/* .most-visited-package{

} */
.mvp-heading{
    font-weight: var(--medium);
    font-size: 18px;
    padding: 2rem 0rem;
    border-bottom: 1px solid #e94560;
}
.mv-line{
    border-bottom: 1px solid #C4C4C480;
}
.no-result{
    width: 100%;
    text-align: center;
    margin-top: 1rem;
}