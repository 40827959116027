.StatusPage{
    margin: 16px 20px;
}
.status-heading{
    margin: 12px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.status-heading>p{
    font-weight: 600;
    letter-spacing: 0.082em;
}
.status-heading #Status-view-all{
    color: #55cdec;   
}
.Status-card{
display: flex;
flex-direction: row;
justify-content: space-between;
}
.frequency{
    width: 193px;
}
.strike-rate{
    width: 193px;
}
.frequency>.progress_bar{
    height: 180px;
    margin-right: 12px;
    /* width: 93%; */
}
.strike-rate>.progress_bar{
    height: 180px;
    /* width: 93%; */
    margin-left: 12px;
}
.Status-bottom{
    margin: 36px 0px;
}
.Status-Badges{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1%;
}
.Status-Badge{
    display: flex;
    flex-direction: column;
}
.Status-Badge>img{
    width: 115px;
    height: 115px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    /* margin-right:10px; */
    filter: grayscale();
    opacity: 0.8;
}
.Status-Badge>p{
    align-self: center;
    margin-top: 6px;
    color: #fbcc77;
}
@media screen and (max-width: 1000px ){
    .frequency{
        width: 155px;
    }
    .strike-rate{
        width: 155px;
    }
    .frequency>.progress_bar{
        height: 144px;
    }
    .strike-rate>.progress_bar{
        height: 144px;
        margin-left: 12px;
    }
    .Status-Badge {
        margin-top: 10px;
    }
    .Status-Badge>img{
        width: 85px;
        height: 104px;
    }
    .Status-Badge>p{
        margin-top: 16px;
    }
}
@media screen and (max-width: 600px){
    .Status-card{
        justify-content: unset;
    }
     .frequency{
        width: 152px;
    }
    .strike-rate{
        width: 152px;
    } 
    .status-heading>p{
        margin-left: 0px;
    }
    .Status-Badge>img{
        height: auto;
    }
    .frequency>.progress_bar{
        height: 150px;
    }
    .strike-rate>.progress_bar{
        height: 150px;
        margin-left: 12px;
    }
}