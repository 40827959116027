.search-filter{
    display: flex;
    flex-direction: column;
}
.search-filter-header{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e94560;
    padding-bottom: 1rem;
    margin-bottom: 0.5rem;
    font-size: 18px;
}

.sf-heading{
    font-size: 1.4vw;
    /* font-size: 20px; */
    font-weight: var(--medium);
}

.search-clear{
    color: var(--text-color-type1);
    font-size: 1.2vw;
    cursor: pointer;
}
.selected-category{
    padding: 1.5rem 0rem;
    border-bottom: 1px solid #C4C4C4;
    margin-bottom: 1rem;
}

.not-selected,.selected-category{
    display: flex;
    flex-wrap: wrap;
}

.cat-show-more{
    color: var( --text-color-type3);
    margin: 1rem 0rem;
    cursor: pointer;
}
.rating-value{
    display: flex;
    width: 48%;
    justify-content: space-between;
    align-items: center;
}
.rating-min,.rating-max{
    background-color: var( --btn-color-type7);
    color: var(--text-color-type9);
    padding: 8px 12px;
    border-radius: 4px;
    margin: 0px 4px;

    width: 28px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.filter-apply-btn{
    margin-top: 2.5rem;
}
.filter-apply-btn .redbtn-type2{
    border-radius: 8px;
    font-size: 24px;
    padding: 8px;
}

@media screen and (max-width:1024px){
    /* .selected-category{
        padding: 0.5rem 0rem;
    }
    .cat-show-more {
       font-size: 12px;
        margin: 0.75rem 0rem;
    }
    .rating-type .sf-sub-header{
        margin-bottom: 0rem;
    }
    .rating-value{
        width: 70%;
    }
    .filter-apply-btn {
        margin-top: 1.5rem;
    }*/
    .filter-apply-btn {
       width: 80%;
    } 
}
@media screen and (max-width:600px){
    .sf-heading{
        font-size: 18px;
    }
    .search-clear{
        font-size: 14px
    }
    .filter-apply-btn {
        margin: 2.5rem 0rem;
        width: 80%;
    }
}