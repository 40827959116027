@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;500&family=Overpass:wght@300;400;500&display=swap');

*{
  margin: 0;
  padding: 0;
  /* font-family: 'Outfit', sans-serif; */
  font-family: var(--family1);
  font-weight: var(--regular);
}
body{
  background-color:var(--Dark-Blue-Background);
  color: var(--text-color-type8);
  font-weight: var(--regular);
}