.trip-body-wrapper {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: centers;
  padding: 2% 5%;
  margin: auto;
  padding-top: 116px;
}
/* --------------- Left Section -------------- */
.trip-body-left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 45%;
}
.trip-body-left > h2 {
  color: #fbcc7a;
  font-size: 2.4rem;
}
.trip-Overall-card {
  width: 100%;
  display: flex;
  background-color: #414040;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-radius: 10px;
  padding: 15px;
  margin: 20px 0;
  box-sizing: border-box;
}
.trip-Overall-card > h3 {
  color: #989898;
  font-size: 1.8rem;
}
.Overall-Progress {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
  box-sizing: border-box;
}
.Overall-Progress > .CircularProgressbar {
  width: 40%;
  vertical-align: middle;
  padding: 7%;
  box-sizing: border-box;
}
.Overall-Text {
  text-align: center;
  margin: auto;
  width: 60%;
  font-size: 1.7rem;
  font-weight: bold;
  word-spacing: 3px;
  letter-spacing: 2px;
  color: #fbcc7a;
}
.Location-Place-rating {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.Location-Place-rating > .progress_bar {
  width: 100%;
}
.Location-rating {
  width: 48%;
}
.Location-rating > .ProgressBar {
  width: 100%;
}
.Place-rating {
  width: 48%;
}
.Remarks {
  width: 100%;
  min-height: 200px;
  max-height: auto;
  margin: 10px auto 10px 0px;
  background-color: #414040;
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.Remark-Heading {
  margin: 10px;
  color: #989898;
}
.Remark-Texts {
  width: 90%;
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.Text-Rem {
  margin: 2.5px 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.Rem-Dot {
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 2px solid #ee0505;
}
.Rem-inner-dot {
  background-color: #ee0505;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: auto;
}
/* ------------ Right Section ----------------- */
.trip-body-right {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: 60px 0px 0px auto;
  text-align: center;
}
.Schedule-title {
  width: 100%;
  background-color: #414040;
  padding: 10px 0px;
  border-radius: 5px;
  color: #fbcc7a;
}
/* ------------ Schedule - Body ------------- */
.Schedule-body {
  box-sizing: border-box;
  margin: 20px auto;
  width: 100%;
  background-color: #414040;
  padding: 10px 20px;
  border-radius: 5px;
}
.Schedule-Day {
  box-sizing: border-box;
  width: 100%;
  height: 68px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  background-color: #1c1c1c;
  border-radius: 10px;
  margin: 10px auto;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}
.Schedule-Day-Heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  color: #989898;
  font-size: 18px;
}
.Schedule-Day-Date {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.Schedule-Expand-btn {
  width: 40px;
  height: 40px;
  background-color: #fbcc7a2f;
  border-radius: 50%;
  border: 2px solid #fbcc7a;
  transition: all 0.2s ease-in-out;
}
.Expand-btn-expanded {
  background-color: #c4c4c444;
  border: 2px solid #c4c4c4;
}
/* ------------ Schedule - Body ------------- */
/* ------------ Schedule - Places-Card ------------- */
.Schedule-Day-Body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.Schedule-Place {
  /* margin: 15px auto; */
  display: flex;
  height: 179px;
  width: 90%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.Place-connector {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40px;
}
.Line-connector {
  width: 60px;
  height: 0px;
  background-color: #c4c4c4;
  border: 2px solid #c4c4c4;
  transform: rotate(90deg);
  margin: 35px 0px;
}
.Place-card-time {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 25%;
  font-size: 0.7rem;
}
.Dot-connector {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  margin: 0px 20px;
  width: 33px;
  height: 33px;
  border-radius: 50%;
  border: 2px solid #fbcc7a;
}
.Connector-Inner-Circle {
  background-color: #fbcc7a;
  height: 25px;
  width: 25px;
  border-radius: 50%;
}
.Schedul-Place-Card {
  width: 80%;
  height: 90px;
  display: flex;
  margin: 0px 0px 0px 15px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  border: 2px solid #fbcc7a;
  background-color: #414040;
}
.Schedule-Place-Img {
  width: 40%;
  height: 100%;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.Schedule-Place-Details {
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  margin-left: 10px;
}
.Schedule-Place-Details > h3 {
  font-size: 1.5rem;
}
.Schedule-Place-Details > p {
  font-size: 1rem;
}
/* ------------ Schedule - Places-Card ------------- */
.trip-Continue-Btn {
  margin-top: 10px;
  width: 100%;
  background-color: #fbcc7a;
  padding: 10px 0px;
  border-radius: 5px;
  color: #414040;
  cursor: pointer;
}
/*-------------------------------PopUp-card-css----------------------*/
.tripPage-Popup-content{
  /* z-index: 9999; */
  /* position: fixed; */
}
.tripPage-wrapper{
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tripLogin-page{
    z-index: 9999;
    width: 16rem;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    background-color: #d9d9d9;
    border-radius: 4PX;
    padding: 36px 16px 24px 16px;
}
.tripLogin-btn{
    text-decoration: none;
    color: #fff;
    cursor: pointer;
    width: 100%;
    height: 36px;
    background-color: #414040;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
}
.tripLogin-text>p{
  text-align: center;
  color: #1b1b1b;
  font-weight: bold;
}
@media only screen and (max-width: 600px) {
  .trip-body-wrapper {
    flex-direction: column;
    align-items: center;
    padding: 5% 0%;
  }
  .trip-body-left > h2 {
    color: #fbcc7a;
    font-size: 1.5rem;
    text-align: center;
  }
  .trip-body-left {
    width: 99%;
    margin: auto;
    align-items: center;
  }
  .Overall-Progress > .CircularProgressbar {
    width: 40%;
    vertical-align: middle;
    padding: 5%;
    box-sizing: border-box;
  }
  .trip-Overall-card {
    width: 100%;
  }
  .trip-body-right {
    display: flex;
    flex-direction: column;
    width: 99%;
    margin: 10px auto;
    text-align: center;
  }
  .Schedule-body {
    padding: 0px 10px;
  }
  .Schedule-Day {
    height: 68px;
  }
  .Place-card-time {
    width: 30%;
    font-size: 0.6rem;
    margin: 0px;
  }
  .Place-card-time > h2 {
    margin: 0px;
  }
  .Dot-connector {
    width: 20px;
    height: 20px;
  }
  .Connector-Inner-Circle {
    height: 15px;
    width: 15px;
  }
  .Line-connector {
    width: 40px;
    margin: 25px 0px;
  }
  .Schedul-Place-Card {
    height: 60px;
    margin: 0px 0px 0px 5px;
  }
  .Schedule-Place {
    height: 125px;
  }
  .Schedule-Place-Details {
    margin: auto;
  }
  .Schedule-Place-Details > h3 {
    font-size: 80%;
    margin-left: 5px;
  }
  .Schedule-Place-Details > p {
    font-size: 0.55rem;
  }
  .Location-Place-rating {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
  }
  .Remarks {
    width: 100%;
  }
}
