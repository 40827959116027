.details-wrapper {
    /* position: relative; */
    width: 100%;
    overflow: hidden;
  }
  .details-wrapper .container {
    margin-bottom: 0;
    position: absolute;
  }
  
  .details-header {
    position: relative;
    z-index: 9;
  }
  
  .details-coverpic {
    /* background: url(https://www.travlpoint.com/new/Places/Jharkhand/Images/Science%20Centre/IMG1.jpg) no-repeat; */
    height: 180px;
    width: 100%;
    /* background-position: center;
      background-size: cover; */
  }
  
  .details-coverpic-overlay {
    background: linear-gradient(to right, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3));
    width: 100%;
    height: 100%;
  }
  
  .details-profilepic {
    position: absolute;
    top: 110px;
    left: 5%;
    /* background: url(https://www.travlpoint.com/new/Places/Jharkhand/Images/Science%20Centre/IMG5.jpg) no-repeat; */
    height: 140px;
    width: 140px;
    border-radius: 8px;
    border: 2px solid #fbcc7a;
    /* background-position: center;
      background-size: cover; */
  }
  
  .background-absolute {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  
  .details-icon-background {
    cursor: pointer;
    border-radius: 5px;
    width: 35px;
    height: 35px;
    background-color: rgba(196, 196, 196, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .details-scroll {
    /* position: relative; */
    margin-top: 85px;
    /* overflow-y: scroll; */
  }
  /* .details-scroll::-webkit-scrollbar {
      display: none;
  } */
  
  .details-name-2Button {
    margin: 0 auto 0;
    max-width: 90%;
    display: flex;
    justify-content: space-between;
  }
  
  .details-name {
    font-size: 35px;
    font-weight: 600;
    color: #fbcc7a;
  }
  
  .details-icons {
    width: 90px;
    justify-content: space-between;
    display: flex;
  }
  
  .details-address-rating {
    margin: 5px auto 0;
    max-width: 90%;
    display: flex;
    justify-content: space-between;
  }
  
  .details-address,
  .details-review {
    font-size: 19px;
    color: #989898;
  }
  .details-review {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .details-description {
    margin: 25px auto 0;
    max-width: 90%;
  }
  
  .deatils-heading {
    font-size: 25px;
    color: white;
    margin-bottom: 8px;
  }
  
  .details-desc {
    font-size: 17px;
    color: #989898;
  }
  
  .details-timming-holiday {
    margin: 25px auto 0;
    max-width: 90%;
    display: flex;
  }
  
  .details-timming {
    width: 50%;
  }
  
  .details-holiday {
    width: 50%;
  }
  
  .details-flex {
    display: flex;
  }
  
  .details-time-card {
    color: white;
    display: flex;
    align-items: center;
    padding: 4px 4px 4px 8px;
    font-size: 14px;
    border-radius: 25px;
    margin-right: 20px;
  }
  
  .details-open {
    border: 2px solid #daffbc;
  }
  
  .details-time-open {
    margin-left: 15px;
    padding: 8px 12px;
    background: #daffbc;
    color: #0f6d00;
    font-weight: 700;
    border-radius: 18px;
  }
  
  .details-close {
    border: 2px solid #f65555;
  }
  
  .details-time-close {
    margin-left: 15px;
    padding: 8px 10px;
    background: #f65555;
    color: #8d0000;
    font-weight: 700;
    border-radius: 18px;
  }
  
  .details-holiday-card {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin-right: 10px;
    border: 2px solid #fbcc7a;
    font-size: 19px;
    color: #8d0000;
    background: #f65555;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .details-categories {
    margin: 25px auto 0;
    max-width: 90%;
    overflow-x: scroll;
  }
  .details-categories::-webkit-scrollbar {
    display: none;
  }
  .details-categories-border {
    /* border: 2px solid #DAFFBC; */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    border-radius: 25px;
    margin-right: 20px;
  }
  .details-categories-card {
    /* background-color: #DAFFBC; */
    color: #1c1c1c;
    font-size: 19px;
    border-radius: 25px;
    font-weight: 900;
    padding: 4px 12px;
  }
  
  .details-floating-action-btn {
    position: fixed;
    max-width: 30%;
    padding: 6px 10%;
    background-color: #fbcc7a;
    z-index: 9999;
    bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    font-size: 25px;
    color: #1c1c1c;
    font-weight: 800;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
      rgba(0, 0, 0, 0.22) 0px 15px 12px;
  }
  
  @media screen and (max-width: 800px) {
    .details-timming-holiday {
      margin: 15px auto 0;
      flex-direction: column;
    }
    .details-timming {
      width: 100%;
    }
    .details-holiday {
      width: 100%;
      margin-top: 15px;
    }
  
    .details-floating-action-btn {
      max-width: 40%;
      font-size: 20px;
    }
  }
  
  @media screen and (max-width: 600px) {
    .details-coverpic {
      height: 150px;
    }
    .details-profilepic {
      top: 100px;
      height: 100px;
      width: 100px;
    }
    .details-scroll {
      margin-top: 70px;
    }
    .details-name {
      font-size: 25px;
    }
    .details-address-rating {
      margin: 10px auto 0;
    }
    .details-address,
    .details-review {
      font-size: 16px;
    }
    .details-description {
      margin: 25px auto 0;
      max-width: 90%;
    }
  
    .deatils-heading {
      font-size: 22px;
      color: white;
      margin-bottom: 6px;
    }
  
    .details-desc {
      font-size: 15px;
    }
    .details-timming-holiday {
      margin: 15px auto 0;
      flex-direction: column;
    }
    .details-timming {
      width: 100%;
    }
    .details-holiday {
      width: 100%;
      margin-top: 15px;
    }
  
    .details-time-card {
      font-size: 14px;
      margin-right: 10px;
    }
  
    .details-time-open {
      margin-left: 5px;
      padding: 6px 8px;
    }
  
    .details-close {
      border: 2px solid #f65555;
    }
  
    .details-time-close {
      margin-left: 5px;
      padding: 6px 8px;
    }
  
    .details-holiday-card {
      height: 35px;
      width: 35px;
      font-size: 16px;
    }
  
    .details-categories {
      margin: 15px auto 0;
    }
    .details-categories-border {
      margin-right: 10px;
    }
    .details-categories-card {
      padding: 5px 14px;
      font-size: 16px;
    }
  
    .details-floating-action-btn {
      max-width: 60%;
      font-size: 18px;
    }
  }
  
  /**************************************Gallery-CSS**********************************************/
  .details-gallery {
    margin: 25px 0 0 5%;
    padding-right: 5%;
    max-width: 90%;
  }
  
  .gallery-section {
    display: flex;
    overflow-x: scroll;
  }
  .gallery-section::-webkit-scrollbar {
    display: none;
  }
  
  .detail-not-function-icon {
    display: inline-block;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 12%;
  }
  
  .detail-icon-circle {
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fbcc7a;
  }
  
  .detail-gallery-img {
    margin-left: 2%;
    height: 250px;
    min-width: 300px;
    border-radius: 12px;
  }
  
  @media screen and (max-width: 600px) {
    .detail-not-function-icon {
      min-width: 22%;
    }
  
    .detail-gallery-img {
      margin-left: 5%;
      height: 35vw;
      min-width: 40vw;
      border-radius: 8px;
    }
  }
  /**************************************Review-CSS**********************************************/
  .details-review-card {
    margin: 25px auto 0;
    max-width: 90%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  
  .details-display-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .Review-section {
    margin-top: 70px;
    position: relative;
    display: flex;
    padding: 20px;
    width: 70%;
    flex-direction: column;
    align-items: center;
    border-radius: 12px;
    background-color: #fff;
  }
  
  .profile-img {
    height: 100px;
    width: 100px;
    /* background: url("https://images.unsplash.com/photo-1504933350103-e840ede978d4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=564&q=80");
    background-size: cover;
    background-position: center; */
    position: absolute;
    top: -50px;
    left: 25px;
    border-radius: 50%;
  }
  .bottom_review_section {
    position: relative;
    height: auto;
    bottom: 10px;
    margin: 4px 24px;
    color: grey;
  }
  .top_review_section {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 72%;
    margin-top: 2px;
    margin-bottom: 13px;
    color: #f65555;
    font-size: 25px;
    font-weight: 500;
  }
  
  .rating-colour {
    color: #f65555;
  }
  @media screen and (max-width: 1000px) {
    .Review-section {
      width: 90%;
    }
  }
  @media screen and (max-width: 600px) {
    .Review-section {
      margin-top: 55px;
      padding: 50px 10px 10px 10px;
      width: 85%;
      border-radius: 8px;
    }
  
    .profile-img {
      height: 80px;
      width: 80px;
      top: -40px;
      left: 25px;
    }
  
    .bottom_review_section {
      width: 85%;
      margin: 0;
    }
    .top_review_section {
      width: 85%;
      margin-top: 2px;
      margin-bottom: 15px;
      color: #f65555;
      font-size: 19px;
      font-weight: 600;
    }
  }
  