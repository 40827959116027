.logout-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    height: 24px;
    background-color: hotpink;
    border-radius: 5px;
    padding: 5px 12px;
    /* margin-left: 24px; */
    cursor: pointer;
}

@media screen and (max-width: 600px){
    .logout-btn{
        height: 42px;
        margin-top: 5px;
        border-radius: 8px;
        font-size: 24px;
    }
    .logout-btn p{
        font-size: 24px;
    }
}