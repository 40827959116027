.newsletter-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 6rem 0rem;
}
.newsletter {
  width: var(--content-width);
  padding: 0px 24px;
  min-height: 16rem;
}
.newsletter-image {
  background-image: url("https://images.unsplash.com/photo-1442570468985-f63ed5de9086?auto=format&fit=crop&q=80&w=1000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dHJhaW4lMjB0cmF2ZWx8ZW58MHx8MHx8fDA%3D");
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 16px;
}

.newsletter-overlay {
  width: auto;
  /* height: 100%; */
  /* height: -webkit-fill-available; */
  background-color: #00000080;
  padding: 2rem 2rem 3rem;
  border-radius: 16px;
}
.newsletter-heading {
  font-size: 36px;
  margin-bottom: 1rem;
}
.news-description {
  color: var(--text-color-type7);
  font-size: 18px;
  width: 53%;
}
.news-subscribe {
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
}
@media screen and (max-width: 1024px) {
  .newsletter-wrapper {
    margin: 3rem 0rem;
  }
  .newsletter-image {
    border-radius: 8px;
  }
  .newsletter-overlay {
    border-radius: 8px;

  }
  .news-description {
    width: 90%;
  }
}

@media screen and (max-width: 600px) {
  .newsletter-wrapper {
    margin: 2rem 0rem;
  }
  .newsletter {
    padding: 0px 16px;
    min-height: auto;
  }
  .newsletter-image {
    border-radius: 16px;
    height: auto;
  }
  .newsletter-overlay {
    height: 140px;
    padding: 1.5rem 1rem 0.85rem;
    border-radius: 16px;
  }
  .newsletter-heading {
    font-size: 24px;
    margin-bottom: 0.5rem;
  }
  .news-description {
    font-size: 10px;
    width: 95%;
  }
  .news-subscribe {
    margin-top: 1rem;
  }
}
