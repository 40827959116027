@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans&family=PT+Sans&display=swap");
.priacy-terms-wrapper {
  max-width: 100%;
  min-width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.privacy-terms-header {
  width: 100%;
  padding: 22vh 0 12vh;
  background-image: linear-gradient(151.17deg, #12100e, #122239);
}

.privacy-terms-header > h2 {
  margin: 0 5vw;
  font-family: "Josefin Sans", sans-serif;
  font-size: 35px;
}

.privacy-policy-main {
  max-width: 90%;
  padding: 20px 5%;
}

.privacy-policy-main > p,.privacy-policy-main > ul > li{
    color: #696969;
    color: wheat;
    opacity: 0.5;    
}

.privacy-policy-main {
  margin: 20px 50px 20px 50px;
  word-wrap: normal;
}

.privacy-policy-main > h3 {
  color: #fbcc7a;
  margin: 10px 0px 5px 0px;
}

.privacy-policy-main > h1 {
  color: white;
  margin: 10px 0px 5px 0px;
}

.privacy-policy-main > ul {
  margin: 10px 50px;
}

@media screen and (max-width: 600px) {
  .privacy-terms-header {
    padding: 15vh 0 5vh;
    display: flex;
    justify-content: center;
  }

  .privacy-terms-header > h2 {
    margin: 0;
    font-family: "Josefin Sans", sans-serif;
    font-size: 30px;
  }
  
  .privacy-policy-main {
    max-width: 100%;
    padding: 10px 10px;
  }
  
  .privacy-policy-main {
    margin: 10px 0px;
    word-wrap: normal;
  }
  
  .privacy-policy-main > ul {
    margin: 10px 20px 10px 50px;
  }
}
