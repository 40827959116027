.package_details_wrapper {
    width: 100%;
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
  }
  
  .package_details {
    width: 90%;
    width: 90%;
    padding: 14vh 5% 0vh;
  }
  
  .scorllWithoutBar {
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  
  .scorllWithoutBar::-webkit-scrollbar {
    display: none;
  }
  
  .package_gallery {
    margin-top: 3vh;
    width: 100%;
    height: 60vh;
    display: flex;
    justify-content: space-between;
  }
  
  .package_img1 {
    height: 100%;
    width: 49%;
  }
  
  .rhs_gallery {
    height: 100%;
    width: 49%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .package_img2 {
    height: 48%;
    width: 100%;
  }
  
  .package_img3 {
    position: relative;
    height: 48%;
    width: 100%;
  }
  
  .showAllPicture {
    display: inline-block;
    padding: 1vh 2vw;
    background-color: rgba(255, 255, 255, 0.2);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    color: #fbcc7a;
    font-size: 15px;
    cursor: pointer;
    border-radius: 1vmin;
    position: absolute;
    right: 10%;
    bottom: 10%;
  }
  
  .showAllPictureWrapper {
    display: flex;
    align-items: center;
  }
  
  .package_info {
    margin-top: 3vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  
  .package-info-card {
    display: flex;
    align-items: center;
  }
  
  .info-text {
    font-size: 3vmin;
  }
  
  .package_desc {
    margin-top: 3vh;
    width: 100%;
    height: 90vh;
    display: flex;
    justify-content: space-between;
  }
  
  .package_overview {
    width: 69%;
    padding: 3vh 2.5vw;
    background: #414040;
    border-radius: 1.5vmin;
    height: 100%;
  }
  
  .package_tabs {
    display: flex;
    justify-content: center;
  }
  
  .package_tab {
    padding: 0.5vmin 12vmin;
    color: #989898;
    cursor: pointer;
    transition: 0.5s ease-in-out;
  }
  
  .active_tab {
    color: white;
    font-weight: 600;
  }
  
  .center_tab {
    border-left: 2px solid #989898;
    border-right: 2px solid #989898;
  }
  
  .package_description {
    margin: 5vh 1vw 0;
    font-size: 3vmin;
    white-space: pre-line;
    text-align: justify;
  }
  
  .package_rhs_desc {
    width: 24%;
  }
  
  .pacakge_plan {
    width: 100%;
    font-size: 3.2vmin;
    height: 8vh;
    background-color: #fbcc7a;
    color: black;
    font-weight: bold;
    border: 2px solid #fbcc7a;
    border-radius: 1vmin;
    cursor: pointer;
    transition: 0.5s ease-in;
    margin-bottom: 2vh;
  }
  
  .pacakge_plan:hover {
    background-color: black;
    color: #fbcc7a;
  }
  
  .package_reviews {
    background-color: #414040;
    height: 81vh;
    width: 92%;
    border-radius: 1.5vmin;
    padding: 2.5vh 4%;
  }
  
  .package_review_title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 1vh;
  }
  
  .package_review_card {
    margin-top: 2vh;
    padding: 2vh 2%;
    background-color: #c4c4c4;
    border-radius: 1vmin;
    color: #eeeeee;
  }
  
  .top_layer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .review_userdata {
    display: flex;
    align-items: center;
    font-size: 17px;
  }
  
  .pack_review_dp {
    margin-right: 5px;
    height: 6vmin;
    width: 6vmin;
    border-radius: 100%;
  }
  
  .pack_review_rating {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
  }
  
  .package_review_of_person {
    margin: 2vmin 2vmin 0;
    font-size: 15px;
  }
  
  .placestovisitList {
    margin: 10vh 0 3vh;
    padding: 2% 2%;
    background-color: #414040;
    border-radius: 1.5vmin;
  }
  
  .package-Sub-Title {
    font-size: 20px;
    font-weight: 600;
  }
  
  .placestovisitList .trending_card {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    /* margin-top: 4vh; */
    padding-top: 4vh;
    align-items: center;
    width: 100%;
    height: auto;
  }
  
  .placestovisitList .trending_card {
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  
  .trending_card::-webkit-scrollbar {
    display: none;
  }
  
  .placestovisitList .trending_card .card-section {
    min-width: calc(100% / 4 - 24px);
  }
  .placestovisitList .trending_card .card-section .card {
    background-color: #1c1c1c;
    margin: 3vh 1vw;
  }
  
  @media screen and (max-width: 600px) {
    .package_details {
      width: 100%;
      width: 100%;
      /* padding: 14vh 0% 0vh; */
      padding: 10vh 0% 0vh;
    }
  
    .package_details h2 {
      margin: 0 2% 0;
    }
  
    .package_gallery {
      padding: 0 2%;
      width: 96%;
      flex-direction: column;
    }
  
    .package_img1 {
      height: 49%;
      width: 100%;
    }
  
    .rhs_gallery {
      height: 49%;
      width: 100%;
      flex-direction: row;
    }
  
    .package_img2 {
      height: 100%;
      width: 48%;
    }
  
    .package_img3 {
      height: 100%;
      width: 48%;
    }
  
    .showAllPicture {
      bottom: 2%;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      text-align: center;
    }
  
    .package_info {
      margin: 3vh 2% 0;
      width: 96%;
      flex-wrap: wrap;
    }
  
    .package-info-card {
      min-width: 50%;
      margin-bottom: 2vh;
    }
  
    .info-text {
      font-size: 4vmin;
    }
  
    .package_desc {
      margin-top: 3vh;
      width: 100%;
      height: unset;
      display: flex;
      flex-direction: column;
      justify-content: unset;
    }
  
    .package_overview {
      width: 96%;
      padding: 2vh 2% 3vh;
      border-radius: 0vmin;
      transition: 0.5s ease-in;
    }
  
    .package_tab {
      padding: 0.5vmin 4vmin;
    }
  
    .package_description {
      margin: 2vh 2vw 0;
      font-size: 4vmin;
      text-align: center;
      white-space: pre-line;
    }
  
    .package_rhs_desc {
      width: 96%;
      margin: 2vh 2% 0;
    }
  
    .pacakge_plan {
      font-size: 5vmin;
    }
  
    .package_reviews {
      height: unset;
      width: 96%;
      border-radius: 1.5vmin;
      padding: 2.5vh 3%;
    }
  
    .package_review_title {
      font-size: 5vmin;
    }
  
    .package_review_card {
      padding: 2vh 5%;
    }
  
    .review_userdata {
      font-size: 5vmin;
    }
  
    .pack_review_dp {
      margin-right: 2vmin;
      height: 12vmin;
      width: 12vmin;
    }
  
    .pack_review_rating {
      font-size: 4vmin;
    }
  
    .package_review_of_person {
      margin: 2vmin 2vmin 1vh;
      font-size: 4.5vmin;
    }
  
    .placestovisitList {
      margin: 2vh 0 3vh;
      padding: 3vh 2%;
      background-color: #414040;
      border-radius: 0vmin;
    }
  
    .package-Sub-Title {
      font-size: 6vmin;
    }
    .placestovisitList .trending_card .card-section {
      min-width: calc(100% / 2 - 10px);
    }
    .placestovisitList .trending_card .card-section .card {
      margin: 0vh 2vw;
    }
  }
  