.range-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    height: calc(32px + 1.6rem);
    margin-bottom: 16px;
    justify-content: center;
}

.thumb-size {
    width: 16px;
}

input[type="range"] {
    appearance: none;
    background: transparent;
    border: transparent;
}

input[type="range"]::-webkit-slider-thumb {
    appearance: none;
    pointer-events: all;
    width: 16px;
    height: 16px;
    border-radius: 0;
    border: none;
    cursor: grab;
    background-color: var(--icon-color-type2);
}

input[type="range"]::-webkit-slider-thumb:active {
    cursor: grabbing;
}



.input-wrapper {
    width: calc(100% + 16px);
    margin: 0 calc(16px / -2);
    position: absolute;
    height: 16px;
}

.rating-min-max {
    position: absolute;
    bottom: -3px;
    display: flex;
    justify-content: space-between;
    width: 96%;
    margin-top: 5px;
}

.rating-min-max span{
    font-size: 14px;
}

.control-wrapper {
    width: 92%;
    position: absolute;
    height: 16px;
    left: 12px;
}

.input {
    position: absolute;
    width: 100%;
    pointer-events: none;
    appearance: none;
    height: 100%;
    opacity: 0;
    z-index: 3;
    padding: 0;
}

.rail {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    height: 6px;
    border-radius: 3px;
    background: lightgrey;
}

.inner-rail {
    position: absolute;
    height: 100%;
    background: var(--icon-color-type1);
    opacity: 0.5;
}

.control {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    position: absolute;
    background: var(--icon-color-type1);
    top: 50%;
    margin-left: calc(16px / -2);
    transform: translate3d(0, -50%, 0);
    z-index: 2;
}