@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&family=PT+Sans&display=swap');
.Loading{
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Loading > img{
    height: 200px;
    width: 200px;
}

.Blog_Detail_Body_wrapper{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.Blog_Details_Header{
    width: 100%;
    /* height: 60vh; */
    background-image: linear-gradient(151.17deg, #12100E, #2d2865);
    /* background-image: linear-gradient(135deg, var(--Dark-Blue-Primary), #2d2865); #122239*/
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}
.Blog_Detail_Heading{
    margin-top: 100px;
    text-align: center;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: space;
}
.Blog_Detail_Heading > h3{
    margin: auto;
    font-size: 20px;
    color: #989898;
    font-family: 'PT Sans', sans-serif;
}
.Blog_Detail_Heading > h2{
    margin: 20px auto;
    width: 50%;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 30px;
}
.Blog_Header_line{
    width: 10%;
    margin: auto;
    background-color: #55CDEC;
    border: 2px solid #55CDEC;
    transform: rotate(-0.44deg);
}
.Blog_Detail_Links{
    margin: 50px 0px 20px 0px;
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.Blog_share_links{
    display: flex;
    flex-direction: row;
    padding: 10px;
}
.Blog_Like_links{
    padding: 10px 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.Blog_Details_Like{
    margin: 0px 10px;
    padding: 5px 15px;
    background-color: #414040;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
}
.Blog_Details_Like:hover{
    background-color: var(--Accent);
}
.Blog_Like_links .isLiked{
    font-size: 30px;
    color: #55CDEC;
    cursor: pointer;
}
.Blog_Like_links > h3{
    margin: 0px 5px;
    color: #55CDEC;
}
.link_share{
    transform: rotate(-45deg);
}
.Blog_Share_icons{
    margin: 0px 10px;
    cursor: pointer;
}
.Blog_details_body{
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.Blog_Cover_Picture{
    border-radius: 10px;
    margin: 15px 0px;
    width: 100%;
    height: 400px;
}
.Blog_details_body .html-render{
    word-wrap: break-word;
    width: 100%;
}
.Blog_Detail_Body_wrapper .footer-wrapper{
    margin-top: 36px;
}
/* ------------------------ Blog Body ---------------------- */
.Blog_Body_Para{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.Blog_Body_Para > h2{
    width: 100%;
    margin: 10px 0px;
}
.Blog_Body_Para > p{
    margin: 5px 0px;
    width: 100%;
    font-size: 18px;
    line-height: 20px;
    color: #CACACA;
}
.Blog_Para_Single_Pic{
    margin: 10px;
    width: 100%;
    height: 300px;
    border-radius: 10px;
}
.Blog_Para_Two_Pic{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.Blog_Para_Two_Pic{
    width: 100%;
}
.Blog_Para_First_Of_two{
    margin: 10px 0px;
    width: 49%;
    height: 300px;
    border-radius: 10px;
}
.Blog_Para_Second_Of_two{
    margin: 10px 0px;
    width: 49%;
    height: 300px;
    border-radius: 10px;
}
@media (max-width:600px){
    .Blog_Para_Single_Pic{
        height: 200px;
    }
    .Blog_Para_First_Of_two{
        height: 200px;
    }
    .Blog_Para_Second_Of_two{
        height: 200px;
    }
}
/* ------------------------ Blog Body ---------------------- */
@media (max-width:600px){
    .Blog_Detail_Heading > h3{
        width: 90%;
    }
    .Blog_Detail_Heading > h2{
        width: 90%;
        font-size: 20px;
    }
    .Blog_Header_line{
        width: 30%;
    }
    .Blog_details_body{
        width: 90%;
    }

    .Blog_Detail_Links{
        margin: 20px 0px 20px 0px;
        flex-direction: column;
        align-items: center;
    }
    .Blog_Cover_Picture{
        height: 200px;
    }
    .Blog_Details_Like{
        font-size: 20px;
    }
}