.place-card{
    padding: 1.5rem 0rem;
    display: flex;
    justify-content: space-between;
}

.place-card-details{
    width: 61%;
}

.place-category{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
.place-category .redbtn-type2{
    background-color: var(--btn-color-type6);
    width: fit-content;
    margin: 4px 6px;
    padding: 4px 12px;
    font-size: 12px;
}
.place-description{
    font-size: 12px;
    text-align: justify;
    font-weight: 400;
    color: var(--text-color-type10);
}
.place-timings{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 8px;
}
.place-clock{
    display: flex;
    width: 75%;
}
.place-time{
    margin-left: 8px;
    width: 100%;
    display: flex;
    align-items: center;
}

.place-timings .redbtn-type2{
    width: 36%;
    padding: 6px 8px;
    border-radius: 4px;
}
.place-timing{
    display: flex;
    width: 100%;
}
.btn-more{
    width: 36%;
}

.btn-more  .redbtn-type2{
    width: 100%;
}

@media screen and (max-width:1024px){
    /* .place-card{
        flex-direction: column;

    }
    .place-card .place-img{
        width: auto;
        height: 144px;
    }
    .place-card-details{
        width: auto;
        margin-top: 1rem;
    }
    .place-card-details .place-address-row .place-address .place-location{
        font-size: 18px;
    }
    .place-timings .place-clock{
        display: flex;
        width: 50%;
    }
    .place-timings .place-clock .place-timing{
        display: flex;
        width: 100%;
    }
    .place-timings .redbtn-type2{
        width: 36%;
    } */
}
@media screen and (max-width:600px){
    .place-card{
        background-color: var(--bg-color-type3);
        flex-direction: column;
        padding: 0;
        margin: 16px 0px;
        border-radius: 8px;
    }
    .place-card .place-img{
        width: auto;
        height: 180px;
    }
    
    .place-card .place-img .place-tag{
        width: 30%;
        left: -10%;
    }

    .place-card .place-img img{
        border-radius: 8px 8px 0px 0px;
    }
    .place-card-details{
        width: auto;
        padding: 20px 20px;
        margin-top: 0;
    }
    .place-card-details .place-name-header span{
        font-size: 24px;
    }
    .place-card-details .place-name-header .redbtn-type2{
        font-size: 12px;
        /* width: 20%; */
        /* padding: 2px 6px; */
    }
    .place-card-details .place-address-row .place-address .place-location{
        font-size: 18px;
    }
    .place-category{
        display: none;
    }
    .place-timings{
        display: none;
    }
    .place-timing{
        display: flex;
        width: auto;
    }
}