.underline-heading{
    color: var(--text-color-type1);
    font-size: 40px;
    font-weight: var(--medium);
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    flex-direction: column;
    align-items: center;
}
.underline-heading-line{
    background-color: var(--bg-color-type6);
    width: 14rem;
    height: 4px;
    border-radius: 4px;
    margin-top: 1rem;
}
@media screen and (max-width:1024px) {
    .underline-heading{
        font-size: 34px;
        /* margin-top: 0; */
    }
}
@media screen and (max-width:600px) {
    .underline-heading{
        font-size: 18px;
        margin-top: 1.5rem;
    }
    .underline-heading-line{
        width: 8rem;
        height: 2px;
        margin-top: 0.4rem;
    }
}