.photo-preview-bg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color:rgba(0, 0, 0, 0.85);
    z-index: 999;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.preview-slider-wrapper{
    min-width: 60vw;
    max-width: 60vw;
    min-height: 70vh;
    max-height: 70vh;

}

.picture-wrapper{
    padding: 0;
    display: flex;
    justify-content: center;
}

.active-image-preview{
    min-width: 60vmax;
    max-width: 60vmax;
    min-height: 70vmin;
    max-height: 70vmin;
    margin: 7.5vh 0 3vh;
}

.select-image{
    position: absolute;
    bottom: 3%;
    width: 100vw;
    padding: 2.7vh 0;
    background: rgba(255, 255, 255, 0.4);
    display: flex;
    justify-content: center;
}

.select-element{
    width: 7vmin;
    height: 7vmin;
    margin-left: 2vmin;
}

.arrow-circle{
    height: 8.5vmin;
    width: 8.5vmin;
    border-radius: 100%;
    background: #4B4B4B;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    transition: 0.7s ease-in;
    cursor: pointer;
}

.pre-arrow{
    top: 33.25vmin;
    left: -15vmin;
}

.next-arrow{
    top: 33.25vmin;
    right: -15vmin;
}

.active-element{
    transform: scale(1.3);
    margin: 0 2vmin 0 4vmin;
    border: 2px solid white;
}

.back-button{
    top: 2vh;
    right: 10%;
}

@media screen and (max-width: 600px) {    
    .preview-slider-wrapper{
        min-width: 75vw;
        max-width: 75vw;
        min-height: 55vh;
        max-height: 55vh;
    }
    
    .active-image-preview{
        min-width: 75vw;
        max-width: 75vw;
        min-height: 55vh;
        max-height: 55vh;
        margin: 10vh 0 3vh;
    }
    
    .select-image{
        bottom: 10%;
    }
    
    .arrow-circle{
        height: 5.5vh;
        width: 5.5vh;
    }
    
    .pre-arrow{
        top: 34.5vh;
        left: -6.10vh;
    }
    
    .next-arrow{
        top: 34.5vh;
        right: -6.10vh
    }    
    .back-button{
        height: 6.5vh;
        width: 6.5vh;
        top: 3vh;
        right: 3vw;
        z-index: 999;
    }
}