.search-card-wrapper{
    width: 100%;
    position: relative;
    overflow: visible;
    display: flex;
    justify-content: center;
}
.search-card{
    width: 92vw;
    height: 70px;
    position: absolute;
    top: -36px;
    display: flex;
    justify-content: center;
}
.main-search-card-content{
    display: flex;
    align-items: center;
    background-color: var(--bg-color-type5);
    border-radius: 100px;
    width: 75%;
    padding: 0rem 1rem;
    position: relative;
    
}
.search-card input[type=text]{
    border: none;
    width: 84%;
    height: 30px;
    outline: none;
    padding: 20px 24px;
    font-size: 24px;
    border-radius: 100px;
    color: var(--text-color-type5);
}
.search-card input[type=text]::placeholder{
    color: var(--text-color-type7);
    font-weight: var(--medium);
}
.search-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--btn-color-type4);
    padding: 10px 16px;
    cursor: pointer;
    border-radius: 40px;
    position: absolute;
    width: 60px;
    top: 8px;
    right: 12px;
}

.search-btn .search-icon{
    color: #E94560; 
    font-size: 35px;
}

@media screen  and (max-width:1024px) {
    .search-card-wrapper{
        margin-bottom: 3rem;
        /* width: 76%; */
    }
}
@media screen  and (max-width:600px) {
    .search-card-wrapper{
        margin-bottom: 2rem;
        width: 100%;
    }
    .search-card{
        height: auto;
        width: 82vw;
        top: -20px;
    }
    .main-search-card-content {
        padding: 0;
        width: 100%;
    }
    .search-card input[type=text]{
        font-size: 16px;
        font-size: 16px;
        height: 30px;
        padding: 6px 18px;
        width: 100%;
    }
    .search-btn{
        top: 4px;
        right: 6px;
        padding: 6px 12px;
        width: 34px;
    }
    .search-btn .search-icon{
        font-size: 22px;
    }
}