.categories-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
}
.categories{
    width: var(--content-width);
    padding: 0px 24px;
    display: flex;
    flex-direction: column;
}
.categories-cards{
    display: flex;
    justify-content: space-between;
    margin-top: 2.5rem;
}
.categories-card{
    width: calc(100%/4 - 24px);
}
@media screen and (max-width:1024px) {
    .categories-cards{
        margin-top: 1.5rem;
    }
    .categories-card{
        width: calc(100%/4 - 12px);
    }
}

@media screen and (max-width:600px) {
    .categories{
        padding: 0px 16px;
    }
    .categories-cards{
        flex-wrap: wrap;
        margin-top: 0.5rem;
    }
    .categories-card{
        margin: 6px 0px;
        width: calc(100%/2 - 6px);
    }
}