.search-desination{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* min-height: 24vw; */
    font-size: 24px;
    color: var(--text-color-type10);
}
.search-desination .search-travl{
    font-size: 16vw;
    color: #36334f61;
}
.search-result-content{
    background-color: var(--bg-color-type3);
    border-radius: 16px;
}
.search-result-body{
    padding: 1.5rem;
}
.search-result-header{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.s-result{
    font-weight: var(--medium);
}
.s-sort{
    font-size: 15px;
}
.s-sort span{
    color: var(--text-color-type1);
    margin-left: 4px;
}
.place-card-line{
    border-bottom: 1px solid #C4C4C480;
}

@media screen and (max-width:600px){
    .search-result-content{
        background-color: unset;
    }
    .search-result-body{
        padding: 10px;
    }
    .place-cards{
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
    .place-cards::-webkit-scrollbar {
        display: none;
      }
    .place-card-line{
        display: none;
    }
    .search-desination{
        min-height: 64vh;
    }
}