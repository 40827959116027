.search-overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    z-index: 9999;
}

.search-overlay {
    background: rgba(0, 0, 0, 0.85);
    position: fixed;
}
.search-popup-content {
    position: fixed; 
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    bottom: 0%;
    line-height: 1.4;
    background: #1c1c1c;
    border-radius: 12px 12px 0 0;
    max-width: 90%;
    min-height: 88%;
    max-height: 88%;
    overflow-y: scroll;
    z-index: 99999;
    padding-bottom: 2vw;
}
.search-popup-content::-webkit-scrollbar {
    display: none;
}