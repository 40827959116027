.download-app-btn{
    width: fit-content;
    display: flex;
    /* flex-direction: row; */
    /* justify-content: center; */
    align-items: center;
    padding: 10px 16px;
    box-sizing: border-box;
    background-color: var(--btn-color-type1);
    border-radius: 50px;
    cursor: pointer;
    text-decoration: none;
}
.download-app-btn #download-app-txt{
    color: var(--text-color-type8);
    /* font-size: 24px; */
    margin-left: 16px;
    font-weight: var(--light);
    font-size: 1.8vw;
}
.download-app-btn #download-app-txt span{
    font-weight: var(--medium);
}

.download-app-btn .red-btn-icon{
    font-size: 32px;
    color: var(--icon-color-type9);
    width: 3.5vw;
}
@media only screen and (max-width: 1024px) {
    .download-app-btn .red-btn-icon{
        width: 36px;
    }
    .download-app-btn #download-app-txt{
        font-size: 16px;
        margin-left: 6px;
    }
}
@media only screen and (max-width: 600px) {
    .download-app-btn{
        width: 100%;
        padding: 4px 8px;
    }
    .download-app-btn .red-btn-icon{
        width: 16px;
    }
    .download-app-btn #download-app-txt {
        font-size: 7px;
        margin-left: 4px;
    }
}
