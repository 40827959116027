/* Customize the label (the container) */
.chk-box-container {
  display: block;
  position: relative;
  padding-left: 44px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.chk-box-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: var(--icon-color-type10);
  border: 2px solid #fff;
}

/* On mouse-over, add a pink background color */
.chk-box-container:hover input~.checkmark {
  background-color: #e94560;
  border: 2px solid #e94560;
}

/* When the checkbox is checked, add a pink background */
.chk-box-container input:checked~.checkmark {
  background-color: #e94560;
  border: 2px solid #e94560;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.chk-box-container input:checked~.checkmark:after {
  display: block;
  border-radius: 24px;
  height: 16px;
}

/* Style the checkmark/indicator */
.chk-box-container .checkmark:after {
  left: 7px;
  top: -1px;
  width: 4px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* @media screen and (max-width:600px) {
  .chk-box-container:hover input~.checkmark {
    background-color: ;
     border: 2px solid #e94560;
  }
} */