.ProfilePage {
    padding-top: 60px;
  }
  .Profile-top {
    width: 100%;
    height: 100%;
  }
  .Profile-top1 {
    width: 100%;
    /* height: 150px; */
    height: 200px;
    /* background-image: url("https://cdn.pixabay.com/photo/2014/02/27/16/10/tree-276014_960_720.jpg");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat; */
    /* display: flex;
      flex-direction: row;
      position: relative; */
  }
  .Profile-top-overlay {
    display: flex;
    flex-direction: row;
    position: relative;
    height: 100%;
    background: rgba(0, 0, 0, 0.55);
  }
  .Profile-top-right {
    position: relative;
    width: 30%;
  }
  .Profile-pic {
    height: 180px;
    width: 180px;
    border-radius: 150px;
    /* background-image: url("https://i.pinimg.com/236x/55/51/49/555149374ca6a66886d13af2c30a66d5--old-cartoons-classic-cartoons.jpg");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat; */
    position: absolute;
    /* left: 16%; */
    right: 12px;
    /* top: 16%; */
    top: 40%;
  }
  .Profile-top-left {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding: 16px;
  }
  .Profile-name {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .Profile-name > p {
    font-size: 38px;
    font-weight: 900;
    margin-right: 10px;
  }
  .Profile-name #pediticon {
    color: #f24e1e;
    align-self: center;
    cursor: pointer;
  }
  .Profile-top2 {
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    /* margin-left: 13%; */
    height: 45px;
  }
  .Profile-top2a {
    width: 31%;
  }
  .Profile-top2b {
    display: flex;
    flex-direction: row;
    width: 69%;
  }
  .Pdetails {
    display: flex;
    flex-direction: row;
    margin-right: 60px;
    align-items: center;
    /* width: 100%; */
  }
  .Pdetails2 {
    display: flex;
    flex-direction: row;
  }
  .Profile-body {
    display: flex;
    /* flex-direction: row; */
    justify-content: center;
    /* margin-top: 32px */
  }
  .Profile-body-Cards {
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* margin-top: 32px */
    margin: 32px 16%;
    width: 90%;
  }
  .Profile-right {
    /* width: 46%; */
    width: 432px;
    /* height: 450px; */
    height: auto;
    background-color: #1c1c1c;
    /* margin-right: 32px; */
    margin: 0px 16px;
    border-radius: 16px;
  }
  .Profile-left {
    /* width: 46%; */
    width: 432px;
    /* height: 450px; */
    height: auto;
    background-color: #1c1c1c;
    /* margin-right: 32px; */
    margin: 0px 16px;
    border-radius: 16px;
  }
  .Profile-footer {
    background-color: #1c1c1c;
    color: grey;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 28px;
  }
  .profile-Popup-content .updateP-page,
  .ConfirmP-page {
    z-index: 9999;
    position: fixed;
  }
  
  /* update-page-progress bar css */
  .updateP-page-align {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: auto;
  }
  .Pupdate-progress {
    width: 90px;
    height: 82px;
    background-color: #414040;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 24px;
  }
  .updates-text > p {
    color: #1b1b1b;
    font-weight: bold;
  }
  @media screen and (max-width: 1000px) {
    .Pdetails {
      margin-right: 24px;
    }
    .Pdetails > p {
      font-size: 14px;
    }
  }
  @media screen and (max-width: 600px) {
    .ProfilePage {
      /* padding-top: 58px; */
      padding-top: 74px;
    }
    .Profile-top1 {
      /* flex-direction:column; */
      border-radius: 0 0 100% 100%/0 0 60% 60%;
    }
    .Profile-top-overlay {
      flex-direction: column;
    }
    .Profile-top-right {
      display: flex;
      justify-content: center;
      position: relative;
      width: auto;
    }
    .Profile-pic {
      height: 150px;
      width: 150px;
      position: inherit;
      top: 91px;
    }
    .Profile-top-left {
      justify-content: center;
      position: relative;
    }
    .Profile-name {
      position: absolute;
      top: 94px;
    }
    .Profile-name #pediticon {
      font-size: 16px;
    }
    .Logout-btn {
      display: none;
    }
    .Profile-name > p {
      font-size: 24px;
    }
    .Profile-top2 {
      flex-direction: column;
    }
    .Profile-top2b {
      flex-direction: column;
      width: 100%;
    }
    .Pdetails#Pdetails1 {
      width: 100%;
      justify-content: center;
      margin: 77px 0px 0px 0px;
    }
    .Pdetails2 {
      margin: 5px 25px;
      justify-content: space-evenly;
    }
    .Pdetails#Pdetails2b {
      margin: 0px 0px 0px 24px;
    }
    .Pdetails > p {
      font-size: 13px;
    }
    .Profile-body-Cards {
      flex-direction: column-reverse;
      margin: 32px 0px;
    }
    .Profile-left {
      width: auto;
      /* margin:16px 0px; */
      margin: 85px 0px 16px 0px;
    }
    .Profile-right {
      width: auto;
      margin: 16px 0px;
    }
  }
  