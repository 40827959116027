.paginations{
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.paginations .pagination-icon{
    cursor: pointer;
    font-size: 28px;
}
.paginations .pages{
    display: flex;
}
.paginations .page{
    /* margin: 0px 4px; */
    padding: 2px 8px;
    border-radius: 4px;
    cursor: pointer;
}
.paginations .page:hover{
    background-color: var(--btn-color-type1);
}
.active-page{
    background-color: var(--btn-color-type1);
}

@media screen and (max-width:600px) {
    .paginations{
        padding: 0px 10px;
    }
    
}