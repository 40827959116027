@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&family=PT+Sans&display=swap');
.Blog_Body_Wrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.no-result-fount{
    height: 100vh;
    display: flex;
    font-size: var(--Display-M);
    justify-content: center;
    align-items: center;
    color: var(--Accent);
}