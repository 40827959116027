.AboutBanner{
    max-width: 100%;
    max-width: 100%;
    min-height: 100vh;
    min-height: 100vh;
    background-color: #1C1C1C;
    overflow: hidden;
}

.AboutBannerWrapper{
    position: relative;
    margin: 0 10%;
    padding-top: 10%;
    min-width: 80%;
    max-width: 80%;
    display: flex;
}

.AboutBannerLeft{
    min-width: 50%;
    max-width: 50%;
    z-index: 90;
}

.AboutBannerHeading{
    margin: 5% 0 3%;
    font-size: 3em;
    width: 80%;
    font-weight: bolder;
}

.AboutBannerDesc{
    font-size: 1.5em;
    width: 90%;
}

.AboutBannerBGCircle-1{
    width: 30vw;
    height: 30vw;
    position: absolute;
    background-color: #414040;
    border-radius: 50%;
    top: 60vh;
    left: -26vw;
}

.AboutBannerBGCircle-2{
    width: 35vw;
    height: 35vw;
    position: absolute;
    background-color: rgba(65, 64, 64, 0.6);
    border-radius: 50%;
    top: 22vh;
    left: 60vw;
}

.AboutBannerBGCircle-3{
    width: 35vw;
    height: 35vw;
    position: absolute;
    background-color: rgba(65, 64, 64, 0.6);
    border-radius: 50%;
    top: 33vh;
    left: 45vw;
}

.AboutBannerRight{
    min-width: 50%;
    max-width: 50%;
    z-index: 90;
    position: relative;
}

.AboutBannerIMG{
    position: absolute;
    border-radius: 50%;
    border: 2px solid #FBCC7A;
    box-shadow:
      0 1px 1px hsl(0deg 0% 0% / 0.075),
      0 2px 2px hsl(0deg 0% 0% / 0.075),
      0 4px 4px hsl(0deg 0% 0% / 0.075),
      0 8px 8px hsl(0deg 0% 0% / 0.075),
      0 16px 16px hsl(0deg 0% 0% / 0.075)
    ;
}

.AboutBannerIMG-1{
    background: url(https://images.unsplash.com/photo-1609861517208-e5b7b4cd4b87?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=435&q=80);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 17vw;
    height: 17vw;
    right: -13vw;
    top: 30vh;
}

.AboutBannerIMG-2{
    background: url(https://images.unsplash.com/photo-1491555103944-7c647fd857e6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 10vw;
    height: 10vw;
    right: 13vw;
    top: 33vh;
}

.AboutBannerIMG-3{
    background: url(https://images.unsplash.com/photo-1619120238346-978e07731e77?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=869&q=80);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 13vw;
    height: 13vw;
    left: 0;
    top: 5vh;
}

.AboutBannerIMG-4{
    background: linear-gradient(0deg, rgba(65, 64, 64, 0.75), rgba(65, 64, 64, 0.75)), url(https://images.unsplash.com/photo-1547393027-a632f1004ad6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=440&q=80);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 5vw;
    height: 5vw;
    left: 20vw;
    top: 8vh;
    border: unset;
    box-shadow: unset;
}

.AboutBannerIMG-5{
    background: linear-gradient(0deg, rgba(65, 64, 64, 0.5), rgba(65, 64, 64, 0.5)), url(https://images.unsplash.com/photo-1576487248805-cf45f6bcc67f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=806&q=80);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 8vw;
    height: 8vw;
    left: 35vw;
    top: 2vh;
    border: unset;
    box-shadow: unset;
}

.AboutBannerIMG-6{
    background: linear-gradient(0deg, rgba(65, 64, 64, 0.75), rgba(65, 64, 64, 0.75)), url(https://images.unsplash.com/photo-1569081156080-238534923b09?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 5vw;
    height: 5vw;
    left: 30vw;
    top: 30vh;
    border: unset;
    box-shadow: unset;
}

.AboutBannerIMG-7{
    background: linear-gradient(0deg, rgba(65, 64, 64, 0.5), rgba(65, 64, 64, 0.5)), url(https://images.unsplash.com/photo-1605552478421-e979b1bf25f5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1032&q=80);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 6vw;
    height: 6vw;
    left: 27vw;
    top: 57vh;
    border: unset;
    box-shadow: unset;
}

.AboutBannerIMG-8{
    background: linear-gradient(0deg, rgba(65, 64, 64, 0.75), rgba(65, 64, 64, 0.75)), url(https://images.unsplash.com/photo-1600256698796-ecef3f5b1b57?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 5.5vw;
    height: 5.5vw;
    left: 17vw;
    top: 67vh;
    border: unset;
    box-shadow: unset;
}

.AboutBannerIMG-9{
    background: linear-gradient(0deg, rgba(65, 64, 64, 0.5), rgba(65, 64, 64, 0.5)), url(https://images.unsplash.com/photo-1587422023429-24edff4116a5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 8.5vw;
    height: 8.5vw;
    left: 5vw;
    top: 50vh;
    border: unset;
    box-shadow: unset;
}

@media screen and (max-width: 600px) {
    .AboutBannerWrapper{
        margin: 0 5%;
        padding-top: 10vh;
        min-width: 90%;
        max-width: 90%;
        flex-direction: column;
    }

    .AboutBannerLeft{
        min-width: 100%;
        max-width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    
    .AboutBannerHeading{
        margin: 10% 0 4%;
        font-size: 2em;
        width: 90%;
        font-weight: bolder;
        text-align: center;
    }
    
    .AboutBannerDesc{
        font-size: 1.2em;
        width: 90%;
        text-align: center;
    }

    .AboutBannerBGCircle-1{
        width: 60vw;
        height: 60vw;
        top: 73vh;
        left: -26vw;
    }
    
    .AboutBannerBGCircle-2{
        width: 80vw;
        height: 80vw;
        top: 22vh;
        left: 40vw;
    }
    
    .AboutBannerBGCircle-3{
        width: 60vw;
        height: 60vw;
        top: 43vh;
        left: 10vw;
    }
    
    .AboutBannerRight{
        min-width: 100%;
        max-width: 100%;
    }

    .AboutBannerIMG-1{
        width: 40vw;
        height: 40vw;
        right: -13vw;
        top: 30vh;
    }
    
    .AboutBannerIMG-2{
        width: 30vw;
        height: 30vw;
        right: 33vw;
        top: 25vh;
    }
    
    .AboutBannerIMG-3{
        width: 35vw;
        height: 35vw;
        left: 0;
        top: 8vh;
    }
    
    .AboutBannerIMG-4{
        width: 15vw;
        height: 15vw;
        left: 45vw;
        top: 5vh;
    }
    
    .AboutBannerIMG-5{
        width: 20vw;
        height: 20vw;
        left: 70vw;
        top: 10vh;
    }
    
    .AboutBannerIMG-6{
        width: 18vw;
        height: 18vw;
        left: 30vw;
        top: 45vh;
    }
    
    .AboutBannerIMG-7{
        width: 25vw;
        height: 25vw;
        left: 52vw;
        top: 50vh;
    }
    
    .AboutBannerIMG-8{
        width: 12vw;
        height: 12vw;
        left: 56vw;
        top: 20vh;
    }
    
    .AboutBannerIMG-9{
        width: 28vw;
        height: 28vw;
        left: -5vw;
        top: 40vh;
    }    
}