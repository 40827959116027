.UpdateProfile-wrapper ,
.ConfirmProfile-wrapper{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.updateP-page, 
.ConfirmP-page{
    width: 38%;
    /* height: 60%; */
    background-color: #d9d9d9;
    border-radius: 10PX;
    padding: 12px;
}
.updateP-heading{
    background-color: #414040;
    border-radius: 6px;
    padding: 10px 0px;
}
.updateP-heading>p{
    text-align: center;
    font-weight: 600;
}
.updateP-body, .ConfirmP-body{
    background-color: #414040;
    width: 93.5%;
    /* height: 75%; */
    /* height: 66%; */
    border-radius: 10px;
    margin: 14px 0px;
    padding: 16px;
}
.updateP-name{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.updateP-lname{
    margin-left: 32px;
}
.UP-input, .CP-input{
    margin: 6px 0px;
    color: #000;
    border-radius: 5px;
    padding: 6px;
    font-size: 16px;
    width: 93%;
    border: 0;
    background-color: #d9d9d9;
    font-weight: 600;
}
.Up-email>input, .Up-PhoneNo>input, .Up-Dob>input, .Cp-Password{
    width: 97%;
}
.Up-email, .Up-PhoneNo, .Up-Dob , .Cp-Password{
    margin-top: 8px;
}
.Up-Dob>input[type="date"]::-webkit-calendar-picker-indicator{
    /* color:  #fbcc7a; */
    background-color: darkgray;
    z-index: 1;
}
.updateP-btn, .ConfirmP-btn{
    background-color: #040404;
    border-radius: 6px;
    padding: 10px 0px;
    cursor: pointer;
}
.updateP-btn>p , .ConfirmP-btn>p{
    color: #fbcc7a;
    text-align: center;
    font-weight: 700;
}

/* confirmProfile  */
.CP-details{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.CP-pic{
    width: 100px;
    height: 100px;
    border-radius: 100%;
    margin: 10px 0px;
}
.CP-name{
    color: #fbcc7a;
    font-weight: 600;
}

@media screen and (max-width:1024px) {
    .updateP-page{
        width: 65%;
    }
}
@media screen and (max-width:600px) {
    .updateP-page{
        width: 80%;
        /* height: 70%; */
    }
    .updateP-body{
        width: 89%;
        /* height: 70%; */
    }
    .updateP-name{
        flex-direction: column;
    }
    .updateP-lname{
        margin-left: 0;
    }
    .UP-input{
        width: 96%;
    }
    .Up-email>input, .Up-PhoneNo>input, .Up-Dob>input{
        width: 96%;
    }
    /* Profile Confirmation css */
    .ConfirmP-page{
        width: auto;
    }
}