.banner-btn{
    /* width: 26%; */
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    box-sizing: border-box;
    background-color: var(--btn-color-type1);
    border-radius: 30px;
    cursor: pointer;
    text-decoration: none;
}
.banner-btn #banner-txt{
    font-size: 20px;
    color: var(--btn-color-type8);
}

.banner-btn .red-btn-icon{
    font-size: 32px;
    color: var(--btn-color-type8);
    margin-left: 16px;
}

@media only screen and (max-width: 600px) {
    .banner-btn{
        padding: 6px 12px;
    }
    .banner-btn #banner-txt{
        font-size: 16px;
    }
    .banner-btn .red-btn-icon {
        font-size: 24px;
        margin-left: 8px;
    }
}
