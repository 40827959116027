.download-app-card{
    background-color: var(--Dark-Blue-Primary);
    border-radius: 14px;
    padding: 2rem 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.download-app-card img{
    width: 86px;
    border: 1px solid #ffffff33;
    border-radius: 20px;
}

.download-app-card span{
    font-size: 20px;
    text-align: center;
    margin: 1.5rem 0rem;
}

.download-app-card a{
    width: 80%;
}
.download-app-card .redbtn-type2{
    font-size: 22px;
    padding: 8px;
    width: 100%;
}