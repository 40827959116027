:root{

    /* main color */
    --Dark-Blue-Primary:#1F1D36; 
    --Dark-Blue-Background:#151826; 
    --Light-Blue-Primary:#E3E8FF; 
    --Light-Blue-Background:#C6D1FF;
    --Accent:#E94560;

    /*Extra Color*/
    /* --Red:;
    --Green:;
    --Orange:; */

    /* Font */
    --Display-L:34px;
    --Display-M:28px;
    --Display-S:24px;

    --Heading-L:24px;
    --Heading-S:18px;

    --Labels-L:22px;
    --Labels-S:14px;

    --Paragraph-L:20px;
    --Paragraph-M:14px;
    --Paragraph-S:12px;

    
    /* text-color */
    --text-color-type1:#E94560; /*Pink*/
    --text-color-type2:#FBCC7A; /*Golden*/
    --text-color-type3:#55CDEC;  /*Blue*/
    --text-color-type4:#000000;  /*Black*/
    --text-color-type5:#414040;   /*Grey-Dark*/
    --text-color-type6:#989898;   /*Grey*/
    --text-color-type7:#CACACA;  /**Light-Grey*/
    --text-color-type8:#ffffff;  /*white*/
    --text-color-type9:#1F1D36;  /*Purple*/
    --text-color-type10:#EEEEEE; /*off-white*/
   
    /* button-color */
    --btn-color-type1:#E94560; /*Pink*/
    --btn-color-type2:#55CDEC;  /*Blue*/
    --btn-color-type3:#1C1C1C;  /*Dark-1*/
    --btn-color-type4:#1F1D36; /*Purple*/
    --btn-color-type5:#414040; /*Grey-Dark*/
    --btn-color-type6:#989898; /*Grey*/
    --btn-color-type7:#EEEEEE; /*off-white*/
    --btn-color-type8:#ffffff; /*white*/
    --btn-color-type9:#F24E1E; /*Orange*/

    /* icon-color */
    --icon-color-type1:#E94560; /*Pink*/
    --icon-color-type2:#F24E1E; /*Orange*/
    --icon-color-type3:#FBCC7A; /*Golden*/
    --icon-color-type4:#55CDEC; /*Blue*/
    --icon-color-type5:#1F1D36; /*Purple*/
    --icon-color-type6:#989898; /*Grey*/
    --icon-color-type7:#CACACA; /**Light-Grey*/
    --icon-color-type8:#EEEEEE; /*off-white*/
    --icon-color-type9:#ffffff;  /*white*/
    --icon-color-type10:#000000;
    --icon-color-type11: #C1C1C1;

    /* background-color */
    --bg-color-type1:#000000; /*black*/
    --bg-color-type2:#040404; /*Dark-Background*/
    --bg-color-type3:#1F1D36; /*Purple*/
    --bg-color-type3-t:#1F1D3680; /*Purple color 50% opacity*/
    --bg-color-type3-t2:#1F1D36E6; /*Purple color 90% opacity*/
    --bg-color-type4:#414040; /*Grey-Dark*/
    --bg-color-type5:#FFFFFF; /*white*/
    --bg-color-type6:#E94560; /*Pink*/
    --bg-color-type7:#D9D9D9;
    --bg-color-type8: #55CDEC; /*Blue*/
    --bg-color-type9:#E3E8FF;

    /* font-weight */
    --light:300;
    --regular:400;
    --medium:500;

    /* font-family */
    --family1:'Outfit', sans-serif;
    --family2:'Overpass', sans-serif;

    /* font-size */


    /* content-width */
    --content-width: calc(1280px - 48px);
}

/* @media screen and (max-width: 600px) {

} */