.TrendingSec{
    margin-bottom: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.main-div{
    display:flex;
    flex-direction: column;
    width: 100%;
    /* margin :auto; */
    max-width: calc(1280px - 48px);
    padding: 0px 24px;
    justify-content: space-between;
}

.upper_part {
    display:flex;
    justify-content: space-between;
    margin-top: 2.5rem;
}

.main-div .upper_part .drop-menu{
    display: flex;
    justify-content: center;
    align-items: center;
}

.main-div .trending_card {
   display:flex;
   flex-direction: row;
   justify-content : space-between;
   /* justify-content: space-around; */
   margin-top: 40px;
   align-items: center;
   /* width: 97%; */
   height: auto;
   /* padding: 2rem 0rem; */
}

.trending_card .card-section {
    position: relative;
    width: calc(100%/4 - 24px);
}
.main-div .upper_part .paragraph-sec .lower_para {
    font-size: 20px;
}

@media screen and (max-width: 1024px) {
    .TrendingSec{
        margin-bottom: 10px;
    }
    .trending_card .card-section {
        width: calc(100%/3 - 12px);
    }
    .upper_part {
        margin-top: 1rem;
    }
    .main-div .trending_card{
        margin-top: 30px;
    }
}

@media screen and (max-width: 600px) {
    .TrendingSec{
        margin-bottom: 0px;
    }
    .main-div{
        /* padding: 0px 16px;  */
        padding: 0;
    }
    .upper_part {
        margin-top: 0.5rem;
        padding: 0px 16px;
    }
    .main-div .trending_card{
        /* margin-top: 24px; */
        /* margin: 24px 10px 0px 10px; */
        margin: 0;
        padding: 24px 10px 0px 10px;
         
        overflow-x: scroll;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }

    .main-div .trending_card::-webkit-scrollbar {
        display: none;
    }

    .trending_card .card-section {
        position: relative;
        min-width: calc(100%/2 - 10px);
        margin: 0px 6px;
    }
}