.rating{
    display: flex;
    align-items: center;
}
.starIcon{
    color: var(--icon-color-type3);
}
.rate{
    font-size: 18px;
    margin-left: 6px;
}

@media screen and (max-width: 600px) {
    .rate{
        font-size: 12px;
        margin-left: 2px;
    }
    .rating .starIcon{
        width: 14px;
    }
}