.AboutReviewWrapper {
    margin-top: 10vh;
    min-width: 100%;
    max-width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.AboutReviewHeading {
    color: #FBCC7A;
    font-weight: 600;
    margin-bottom: 1em;
}

.AboutReviewDesc {
    max-width: 60vw;
    font-size: 1.8em;
    text-align: center;
    font-weight: bold;
}

.AboutReviews {
    position: relative;
    margin-top: 10vh;
    max-width: 85%;
    min-width: 85%;
}

.AboutReviewCard {
    display: inline-block;
    position: absolute;
}

.AboutReviewDP {
    border-radius: 50%;
    transition: 0.5s;
    cursor: pointer;
}

.AboutReviewDP:hover {
    box-shadow: 0 0 0 4px #040404,
        0 0 0 6px #FBCC7A;
}

.AboutReviewFeedback {
    position: absolute;
    bottom: 109%;
    width: 20vw;
    background: #1c1c1c;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 4px;
    visibility: hidden;
    opacity: 0;
    transition: 0.5s;
    z-index: 90;
    pointer-events: none;
    transform: translateY(-50px);
}

.AboutReviewFeedback::before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background: #1c1c1c;
    bottom: -10px;
    left: 9vw;
    transform: rotate(45deg);
}

.AboutReviewDP:hover .AboutReviewFeedback {
    visibility: visible;
    opacity: 1;
    transform: translateY(0px);
}

.AboutReviewName{
    margin-top: 1vh;
    display: flex;
    justify-content: flex-end;
    color: #989898;
}

.AboutReviewLinks{
    margin: 2em 0;
}

.AboutReviewLink{
    padding: 0.5em 2em;
    font-weight: bolder;
    color: #1c1c1c;
    background-color: #FBCC7A;
    border: 2px solid #FBCC7A;
    border-radius: 4px;
    transition: 0.9s;
    cursor: pointer;
}

.AboutReviewLink:hover{
    color: #FBCC7A;
    border-bottom: 1px solid #FBCC7A;
    background-color: transparent;
    color: #FBCC7A;
}

.AboutReviewCard-1 {
    top: 12vh;
}

.AboutReviewDP-1 {
    width: 12vw;
    height: 12vw;
}

.AboutReviewCard-2 {
    left: 25vw;
    top: 2vh;
}

.AboutReviewDP-2 {
    width: 14vw;
    height: 14vw;
}

.AboutReviewCard-3 {
    left: 50vw;
    top: 20vh;
}

.AboutReviewDP-3 {
    width: 8vw;
    height: 8vw;
}

.AboutReviewCard-4 {
    left: 60vw;
    top: 0vh;
}

.AboutReviewDP-4 {
    width: 9vw;
    height: 9vw;
}

.AboutReviewCard-5 {
    right: 0;
    top: 28vh;
}

.AboutReviewDP-5 {
    width: 7vw;
    height: 7vw;
}

.AboutReviewFeedback-5 {
    transform: translateX(-50%) translateY(-50px);
}

.AboutReviewDP-5:hover .AboutReviewFeedback-5 {
    transform: translateX(-50%) translateY(0px);
}

.AboutReviewCard-6 {
    left: 17vw;
    top: 6vh;
}

.AboutReviewDP-6 {
    width: 6vw;
    height: 6vw;
}

.AboutReviewCard-7 {
    left: 14vw;
    top: 23vh;
}

.AboutReviewDP-7 {
    width: 8vw;
    height: 8vw;
}

.AboutReviewCard-8 {
    left: 5vw;
    top: 42vh;
}

.AboutReviewDP-8 {
    width: 5vw;
    height: 5vw;
}

.AboutReviewCard-9 {
    left: 11vw;
    top: 47vh;
}

.AboutReviewDP-9 {
    width: 10vw;
    height: 10vw;
}

.AboutReviewCard-10 {
    left: 2vw;
    top: 58vh;
}

.AboutReviewDP-10 {
    width: 4vw;
    height: 4vw;
}

.AboutReviewCard-11 {
    left: 25vw;
    top: 38vh;
}

.AboutReviewDP-11 {
    width: 10vw;
    height: 10vw;
}

.AboutReviewCard-12 {
    left: 37vw;
    top: 30vh;
}

.AboutReviewDP-12 {
    width: 11vw;
    height: 11vw;
}

.AboutReviewCard-13 {
    left: 43vw;
    top: 8vh;
}

.AboutReviewDP-13 {
    width: 6vw;
    height: 6vw;
}

.AboutReviewCard-14 {
    right: 3vw;
    top: 8vh;
}

.AboutReviewDP-14 {
    width: 6vw;
    height: 6vw;
}

.AboutReviewFeedback-14 {
    transform: translateX(-50%) translateY(-50px);
}

.AboutReviewDP-14:hover .AboutReviewFeedback-14 {
    transform: translateX(-50%) translateY(0px);
}

.AboutReviewCard-15 {
    right: 8vw;
    top: 30vh;
}

.AboutReviewDP-15 {
    width: 15vw;
    height: 15vw;
}

.AboutReviewCard-16 {
    left: 50vw;
    top: 40vh;
}

.AboutReviewDP-16 {
    width: 9vw;
    height: 9vw;
}

.AboutReviewCard-17 {
    right: 36vw;
    top: 58vh;
}

.AboutReviewDP-17 {
    width: 6vw;
    height: 6vw;
}

@media screen and (max-width: 600px) {
    .AboutReviewWrapper {
        min-height: 90vh;
        max-height: 90vh;
    }

    .AboutReviewHeading {
        margin-bottom: 0.5em;
    }

    .AboutReviewDesc {
        max-width: 90vw;
        font-size: 1em;
    }

    .AboutReviews {
        max-width: 100%;
        min-width: 100%;
    }

    .AboutReviewDP {
        border-radius: 50%;
        transition: 0.5s;
        cursor: pointer;
    }
    
    .AboutReviewDP:hover {
        box-shadow: 0 0 0 4px #040404,
            0 0 0 6px #FBCC7A;
    }
    
    .AboutReviewFeedback {
        bottom: 10%;
        position: fixed;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        width: 60vw;
        padding: 20px;
        box-shadow: 0 0 0 4px #040404,
            0 0 0 6px #FBCC7A;
    }

    .AboutReviewLinks{
        display: none;
    }

    .AboutReviewFeedback::before {
        display: none;
    }

    .AboutReviewCard-1 {
        top: 12vh;
        left: 2%;
    }
    
    .AboutReviewDP-1 {
        width: 22vw;
        height: 22vw;
    }
    
    .AboutReviewCard-2 {
        left: 25vw;
        top: 2vh;
    }
    
    .AboutReviewDP-2 {
        width: 20vw;
        height: 20vw;
    }
    
    .AboutReviewCard-3 {
        left: 50vw;
        top: 20vh;
    }
    
    .AboutReviewDP-3 {
        width: 16vw;
        height: 16vw;
    }
    
    .AboutReviewCard-4 {
        left: 60vw;
        top: 0vh;
    }
    
    .AboutReviewDP-4 {
        width: 18vw;
        height: 18vw;
    }
    
    .AboutReviewCard-5 {
        right: 2%;
        top: 20vh;
    }
    
    .AboutReviewDP-5 {
        width: 14vw;
        height: 14vw;
    }
    
    .AboutReviewFeedback-5 {
        transform: translateY(-50px);
    }
    
    .AboutReviewDP-5:hover .AboutReviewFeedback-5 {
        transform: translateY(0px);
    }
    
    .AboutReviewCard-6 {
        left: 5vw;
        top: 0vh;
    }
    
    .AboutReviewDP-6 {
        width: 12vw;
        height: 12vw;
    }
    
    .AboutReviewCard-7 {
        left: 14vw;
        top: 25vh;
    }
    
    .AboutReviewDP-7 {
        width: 16vw;
        height: 16vw;
    }
    
    .AboutReviewCard-8 {
        left: 5vw;
        top: 42vh;
    }
    
    .AboutReviewDP-8 {
        width: 15vw;
        height: 15vw;
    }
    
    .AboutReviewCard-9 {
        left: 36vw;
        top: 47vh;
    }
    
    .AboutReviewDP-9 {
        width: 16vw;
        height: 16vw;
    }
    
    .AboutReviewCard-10 {
        left: 8vw;
        top: 53vh;
    }
    
    .AboutReviewDP-10 {
        width: 14vw;
        height: 14vw;
    }

    .AboutReviewCard-11 {
        left: 30vw;
        top: 38vh;
    }
    
    .AboutReviewDP-11 {
        width: 16vw;
        height: 16vw;
    }
    
    .AboutReviewCard-12 {
        left: 67vw;
        top: 40vh;
    }
    
    .AboutReviewDP-12 {
        width: 18vw;
        height: 18vw;
    }
    
    .AboutReviewCard-13 {
        left: 35vw;
        top: 13vh;
    }
    
    .AboutReviewDP-13 {
        width: 12vw;
        height: 12vw;
    }
    
    .AboutReviewCard-14 {
        right: 13vw;
        top: 12vh;
    }
    
    .AboutReviewDP-14 {
        width: 15vw;
        height: 15vw;
    }
    
    .AboutReviewFeedback-14 {
        transform: translateY(-50px);
    }
    
    .AboutReviewDP-14:hover .AboutReviewFeedback-14 {
        transform: translateY(0px);
    }
    
    .AboutReviewCard-15 {
        right: 8vw;
        top: 30vh;
    }
    
    .AboutReviewDP-15 {
        width: 15vw;
        height: 15vw;
    }
    
    .AboutReviewCard-16 {
        left: 50vw;
        top: 30vh;
    }
    
    .AboutReviewDP-16 {
        width: 18vw;
        height: 18vw;
    }
    
    .AboutReviewCard-17 {
        right: 16vw;
        top: 53vh;
    }
    
    .AboutReviewDP-17 {
        width: 15vw;
        height: 15vw;
    }
}