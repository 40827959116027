.popular-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;
}

.populars {
    width: var(--content-width);
    padding: 0px 24px;
}

.popular-heading {
    margin-top: 2.5rem;
}

.top-popular-cards {
    display: flex;
    justify-content: space-between;
    margin-top: 2.5rem;
}

.popular-card {
    width: calc(100%/4 - 24px);
}

@media screen and (max-width:1024px) {
    .popular-wrapper{
        margin-bottom: 0rem;
    }
    .popular-card {
        width: calc(100%/3 - 12px);
    }

    .top-popular-cards {
        margin-top: 1.5rem;
    }
}

@media screen and (max-width:600px) {
    .populars{
        padding: 0;
        width: 100%;
    }
    .popular-heading{
        padding: 0px 16px;
    }
    .top-popular-cards {
        /* margin-top: 1.5rem; */
        margin: 0;
        padding: 24px 10px 0px 10px;
         
        overflow-x: scroll;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }

    .top-popular-cards::-webkit-scrollbar {
        display: none;
    }

    .popular-card {
        min-width: calc(100%/2 - 10px);
        margin: 0px 6px;
    }

}