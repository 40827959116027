.whislist-card{
    width: 19.5vw;
    height: 280px;
    position: relative;
}

.whislist-card-top{
    width: 100%; 
    height: 75%;
    border-radius: 8px 8px 0px 0px;
    cursor: pointer;
}
.wl-pl-details{
    background-color: #000000;
    opacity: 0.75;
    position: absolute;
    right: 0px;
    bottom: 30%;
    border-radius: 39px 0px 0px 39px;
    height: 78px;
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.wl-icon{
    background-color: #000000ba;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 40PX;
    border: 4px solid white;
    position: absolute;
    left: 0px;
    bottom: 32%;
    margin: 0px 8px;
    cursor: pointer;
}
.wl-pl-details #name{
    color: #fbcc7a;
    margin-bottom: 0px;
    /* font-size: 20px; */
    font-size: 1.4vw;
    margin-left: 24px;
}
.wl-pl-details #address{
    font-size: 1vw;
    /* font-size: 14px; */
    margin-top: 2px;
    margin-left: 24px;
}
.whislist-card-bottom{
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
    height: 25%;
    width: auto;
    background-color: #d9d9d9;
    border-radius: 0px 0px 8px 8px;
}
.whislist-card-bottom #rate{
    font-size: 1.5em;
    color: #414040;
}
.add-plan{
    font-size: 1.15vw;
    color: #fbcc7a;
    background-color: #414040;
    width: 100px;
    padding: 10px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

@media (min-width:610px) and (max-width:1024px){
    .whislist-card{
        width: 23.5vw;
        height: 224px;
    }
    .wl-pl-details{
        height: 56px;
        width: 62%;
        bottom: 32%;
    }
    .wl-pl-details #name{
        font-size: 1.6vw;
        margin-left: 16px;
    }
    .wl-pl-details #address{
        margin-left: 16px;
    }
    .whislist-card-bottom #rate{
        font-size: 1em;
    }
    .add-plan{
        width: 56px;
    }
}
@media screen and (max-width:600px){
    .whislist-card{
        width: 90.5vw;
        height: 325px;
    }
    .wl-pl-details{
        padding-left: 8px;
    }
    .wl-pl-details #name{
        font-size: 20px;
    }
    .wl-pl-details #address{
        font-size: 14px;
    }
    .add-plan{
        font-size: 16px;
    }

}