.most-visited-card{
    display: flex;
    justify-content: space-between;
    padding: 1.8rem 0rem;
}
.recent-place-img{
    width: 30%;
    cursor: pointer;
}
.most-visited-card .place-img .place-tag{
    display: none;
}
.most-visited-card .place-img{
    width: 100%;
}
.most-visited-place-detail{
    display: flex;
    flex-direction: column;
    width: 65%;
}
.most-visited-place-detail .place-name-header span {
    color: var(--text-color-type8);
    font-size: 16px;
}
.most-visited-place-detail .place-name-header .redbtn-type2{
    width: 100%;
}
.most-visited-place-detail .rating{
    margin: 4px 0px;
    color: var(--text-color-type6);
}
.mvp-description{
    text-align: justify;
    font-size: 12px;
}

@media screen and (max-width:1024px) {
    .most-visited-card .place-img{
        height: 100%;
    }
}