.banner-wrapper{
    width: 100%;
    /* height: 90vh; */
    height: 42vw;
    overflow: hidden;
}
.slide{
   width: 100%;
   /* height: 80vh; */
   min-height: 400px;
   height: 38.7vw;
   background-position: center;
   background-size: cover;
   position: relative;
}
.left-gradient{
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    background: linear-gradient(90deg, #000000 -18%, rgba(0, 0, 0, 0) 90%);
    border-radius: 0px;
}
.main-banner-content{
    width: var(--content-width);
    padding: 0px 24px;
}
.banner-content{
    width: 45%;
    /* height: 100%;
    margin: 9vw 0; */
    height: auto;
    margin: 8rem 0vh;
    display: flex;
    flex-direction: column;
    justify-content: top;
}
.banner-content h2{
    /* font-size: 1.8vw; */
    font-size: 2.5vw;
    font-weight: var(--regular);
    margin-bottom: 1.2rem;
}
.banner-content h1{
    font-size: 4vw;
    font-weight: var(--regular);
    color:var(--text-color-type1);
    margin-bottom: 0.6rem;
}
.sub-head{
    font-size: 1.6vw;
    margin-bottom: 20px;
}
@media only screen and (max-width: 1024px) {
    .banner-wrapper{
        /* height: 44vh; */
        /* height: 67vh; */
        /* height: 58vw; */
        height: auto;
    }
    .banner-content h2{
        margin-bottom: 0.5rem;
    }
    .banner-content h1{
        margin-bottom: 0.3rem;
    }
}
@media only screen and (max-width: 600px) {
    .wrapper{
        height: 65vh;
    }
    .banner-wrapper{
        /* margin-bottom: 1rem; */
        height: auto;
    }
    .banner-content{
        margin: 36% 6% 30% 6%;
        width: 90%;
    }
    .banner-content h2{
        font-size: 3vh;
        margin-bottom: 0.4rem;
    }
    .banner-content h3{
        font-size: 2.5vh;
    }
    .banner-content h1{
        font-size: 4vh;
        margin-bottom: 0.3rem;
    }
    .left-gradient{
        position: absolute;
        width: 80%;
        height: 100%;
        left: 0px;
        top: 0px;
        background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
        border-radius: 0px;
    }
    .main-banner-content{
        padding: 0;
    }
    .sub-head{
        font-size: 4vw;
    }
    .slide{
        height: 55vh;
    }

    /* .search-card{
        padding: 0px 10px;
        width: 90%;
        left: 2.5%;
        display: flex;
        justify-content: center;
        top: -50px;
        border-radius: 8px;
    } */
    .seperator{
        display: none;
    }
    .location-2{
        display: none;
    }
    .location{
        margin: 20px 10px;
    }
    .location h2{
        font-size: 20px;
    }
    .location input[type=text]{
        border: none;
        width: 100%;
        height: 30px;
        outline: none;
    }
}

@media (max-width:1024px) and (min-width:600px) {
    /* .banner-content{
        margin:30% 10%;
        width: 100%;
    } */
    /* .banner-content h2{
        font-size: 3.5vh;
    }
    .banner-content h3{
        font-size: 2.5vh;
    }
    .banner-content h1{
        font-family: 'Ubuntu';
        font-size: 6vh;
        font-weight: 600;
    } */
}
