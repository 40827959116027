.category-card{
    width: 100%;
    /* width: calc(100%/4-24px); */
    /* background-image: url("https://2.bp.blogspot.com/-JYQ8x3mIAq4/UdRNXZb3rtI/AAAAAAAAATs/VpGaowkM4cw/s1600/Lotus-Temple-front+view.jpg"); */
    /* height: 260px; */
    aspect-ratio: 1/1;
    border-radius: 16px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;

    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: relative;
    transition: all 0.4s ease-in-out;
    
}
.category-card:hover{
    transform: scale(1.08);
    transition: all 0.4s ease-in-out;
    /* transition-duration: 1s; */
}
.category-title{
    background: linear-gradient(104.01deg, rgba(255, 255, 255, 0.3) 2.03%, rgba(255, 255, 255, 0.12) 97.1%);
    backdrop-filter: blur(6px);
    padding: 20px 30px;
    
    border-radius: 16px;
    position: absolute;
    bottom: 24px;
    width: 64%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.category-title span{
    font-size: 24px;
    color: var(--text-color-type8);
}
@media screen and (max-width:1024px) {
    .category-title{
        padding: 8px 16px;
        width: 60%;
        border-radius: 8px;
    }
    .category-title span{
        font-size: 16px;
    }
}
@media screen and (max-width:600px) {
    .category-card{
        border-radius: 8px;
    }
    .category-title{
        padding: 16px 18px;
        border-radius: 8px;
    }
    .category-title span{
        font-size: 18px;
    }
}

