.Container h4{
    text-align: left;
    margin:14px 0px 2px 0px;
    color: var(--text-color-type5)
}
.ShareContainer{
    display: flex;
    color: white;
    margin:16px 0px;
    gap: 12px;
}

.Icons{
    border: 2px solid var(--bg-color-type9);
    background-color: var(--bg-color-type5);
    color:  #0088cc;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    padding: 6px;
    border-radius: 100%;
}