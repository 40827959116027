.catePage-wrapper {
    max-width: 100vw;
    max-height: 100vh;
    min-width: 100vw;
    min-height: 100vh;
    overflow: hidden;
  }
  .catePage-wrapper .container {
    margin-bottom: 0;
    position: absolute;
  }
  .catePage-overlay {
    position: relative;
    padding: 3vh 5vw 0;
    width: 90vw;
    height: 97vh;
    background-color: rgba(0, 0, 0, 0.3);
  }
  
  .catePage-header {
    display: flex;
    justify-content: space-between;
  }
  
  .catePage-title {
    font-size: 33px;
    font-weight: bolder;
  }
  
  .catePage-menu {
    display: flex;
    justify-content: space-between;
  }
  
  .catePage-menu-item {
    margin-left: 2em;
    text-decoration: none;
    color: white;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    font-weight: 400;
    font-size: 20px;
  }
  
  .catePage-menu-item:hover {
    color: #fbcc7a;
  }
  
  .catePage-body {
    margin-top: 8vh;
    width: 100%;
    display: flex;
  }
  
  .catePage-text {
    margin-top: 3em;
    width: 50%;
  }
  
  .slick-dots li button:before {
    color: white;
  }
  
  .slick-dots li.slick-active button:before {
    color: white !important;
  }
  
  .catePage-img {
    width: 50%;
  }
  
  .catePage-Slider-Image {
    max-width: 250px;
    margin: 0 auto;
  }
  
  .catePage-Picture-wrapper {
    padding: 0 20px;
  }
  
  .catePage-Picture {
    width: 190px;
    height: 230px;
    border-radius: 8px;
    border: solid 3px white;
  }
  
  .catePage-Place-Title {
    display: flex;
  }
  
  .catePage-Place-name {
    margin-left: 0.8em;
    font-size: 30px;
    font-weight: normal;
  }
  
  .catePage-HR-20 {
    margin: 10px 0 15px 11px;
    width: 20%;
    height: 2px;
    border-radius: 1.75px;
    background-color: white;
  }
  
  .catePage-description {
    max-width: 70%;
  }
  
  .catePage-desc {
    margin-left: 11px;
    font-size: 18px;
    font-weight: bold;
  }
  
  .catePage-HR-35 {
    margin: 20px 0 25px 11px;
    width: 35%;
    height: 2px;
    border-radius: 1.75px;
    background-color: white;
  }
  
  .catePage-PlanButton {
    width: 26%;
    height: 38px;
    border-radius: 19px;
    margin-left: 11px;
    background-color: #fbcc7a;
    border: solid 2px white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 23px;
    transition: 0.7s ease-in-out;
    color: white;
    cursor: pointer;
  }
  
  .catePage-PlanButton:hover {
    background-color: transparent;
    border: solid 2px #fbcc7a;
    color: #fbcc7a;
  }
  
  .catePage-bottom {
    position: absolute;
    padding: 0 8vw 0 5vw;
    width: 87vw;
    height: 28vh;
    background-color: rgba(0, 0, 0, 0.7);
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
  }
  
  .catePage-details {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .cate-details-item {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
  }
  
  .catePage-Key {
    color: #989898;
    font-size: 18px;
    margin-bottom: 5px;
  }
  
  .catePage-value {
    color: white;
    font-size: 22px;
  }
  
  .catePage-cards {
    width: 40%;
    height: 100%;
  }
  
  .catePage-slider-card {
    display: flex;
    align-items: center;
    background-color: black;
  }
  
  .catePage-Card-wrapper {
    margin: auto;
    padding: 4.5vh 10px 1vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .catePage-card-image {
    margin-bottom: 2vh;
    height: 16vh;
    width: 13vh;
    border-radius: 8px;
    border: solid 3px white;
    transition: transform 500ms ease-in-out;
  }
  
  .catePage-card-name {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 14vh;
    transition: transform 500ms ease-in-out;
  }
  
  .catePage-card-name-active {
    transform: scale(1.2);
  }
  
  .catePage-card-image-active {
    transform: scale(1.2);
  }
  
  .catePage-arrow {
    position: absolute;
    margin-top: auto;
    margin-bottom: auto;
    top: 0;
    bottom: 0;
    transition: 0.7s ease-in-out;
    transform: scale(1);
    cursor: pointer;
  }
  
  .catePage-arrow-next {
    right: -20px;
  }
  
  .catePage-arrow-pre {
    left: -20px;
  }
  
  .catePage-arrow:hover {
    color: #fbcc7a;
    transform: scale(1.1);
  }
  
  @media screen and (max-width: 600px) {
    .catePage-wrapper {
      max-width: 100vw;
      max-height: unset;
      min-width: 100vw;
      min-height: 100vh;
      overflow: unset;
      overflow-x: hidden;
    }
  
    .catePage-overlay {
      position: relative;
      padding: 2vh 0vw 0;
      width: 100vw;
      height: auto;
      background-color: rgba(0, 0, 0, 0.3);
    }
  
    .catePage-header {
      justify-content: center;
      flex-wrap: wrap;
    }
  
    .catePage-title {
      font-size: 30px;
    }
  
    .catePage-menu {
      display: none;
    }
  
    .catePage-body {
      margin-top: 8vh;
      width: 100%;
      flex-direction: column;
    }
  
    .catePage-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 25vh;
      min-width: 100%;
      max-width: 100%;
    }
  
    .catePage-img {
      margin: 30px 0 40px;
      min-width: 100%;
      max-width: 100%;
    }
  
    .catePage-Place-Title {
      font-size: 19px;
      display: flex;
      align-items: flex-end;
    }
  
    .catePage-Place-name {
      margin-left: unset;
      font-size: 28px;
      font-weight: normal;
    }
  
    .catePage-HR-20 {
      margin: 8px 0 13px 11px;
      width: 45%;
    }
  
    .catePage-description {
      max-width: 100%;
    }
  
    .catePage-desc {
      margin-left: 11px;
      margin-right: 11px;
      font-size: 16px;
      text-align: center;
    }
  
    .catePage-HR-35 {
      margin: 25px 0 20px 11px;
      width: 75%;
    }
  
    .catePage-PlanButton {
      width: 50%;
      height: 35px;
      margin-left: unset;
      font-size: 21px;
    }
  
    .catePage-Slider-Image {
      max-width: 100%;
    }
  
    .catePage-Picture-wrapper {
      padding: 0 25%;
      display: flex;
      justify-content: center;
    }
  
    .catePage-Picture {
      width: 50%;
      height: 230px;
      border-radius: 8px;
      border: solid 3px white;
    }
  
    .catePage-bottom {
      position: unset;
      padding: 2vh 5vw 3vh 5vw;
      width: 94vw;
      flex-direction: column;
      height: auto;
      background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.2));
    }
  
    .catePage-details {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  
    .cate-details-item {
      margin-top: 15px;
      display: flex;
      flex-direction: column;
    }
  
    .catePage-Key {
      color: #989898;
      font-size: 16px;
      margin-bottom: 5px;
      font-weight: 600;
    }
  
    .catePage-value {
      color: white;
      font-size: 23px;
      font-weight: 600;
    }
  
    .catePage-cards {
      top: 10vh;
      left: -10%;
      position: absolute;
      width: 120%;
      height: 25vh;
    }
  
    .catePage-Card-wrapper {
      padding: 3vh 0 0;
    }
  
    .catePage-card-image {
      margin-bottom: 3vh;
      height: 20vh;
      width: 16vh;
      border-radius: 8px;
      border: solid 3px white;
      transition: transform 500ms ease-in-out;
    }
  
    .catePage-card-name {
      margin-top: 10px;
      font-size: 16px;
      width: 16vh;
    }
  
    .catePage-arrow {
      display: none;
    }
  }
  