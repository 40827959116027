.AboutBoardsWrapper{
    margin-top: 10vh;
    min-width: 100%;
    max-width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.AboutBoardsHeading{
    color: #FBCC7A;
    font-weight: 600;
    margin-bottom: 1em;
}

.AboutBoardsDesc{
    max-width: 60vw;
    font-size: 1.8em;
    text-align: center;
    font-weight: bold;
}

.AboutBoardsMember{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 4vh 5vw 2vh;
    width: 90%;
}

.AboutBoardsCard{
    position: relative;
    width: 300px;
    height: 410px;
    background: rgba(255, 255, 255, 0.05);
    margin: 20px;
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    backdrop-filter: blur(10px);
    transition: 0.7s;
}

.AboutBoardsCard:hover{
    box-shadow: 0 0 0 4px #040404,
                0 0 0 6px #FBCC7A;
}

.AboutBoardsIMG{
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin: 50px 0 30px;
    box-shadow:
	0px 2px 2px 0px hsla(0,0%,0%,0.14), 
	0px 3px 1px -2px hsla(0,0%,0%,0.12), 
	0px 1px 5px 0px hsla(0,0%,0%,0.2);
}

.AboutBoardsName{
    color: white;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    margin: 0 0 10px;
    line-height: 1.1em;
}

.AboutBoardsDesignation{
    color: #989898;
    letter-spacing: 2px;
    text-align: center;
    margin: 0 0 40px;
    line-height: 1.1em;
    font-size: 13px;
    font-weight: 300;
    text-transform: initial;
}

.AboutBoardsSocial{
    display: flex;
}

.AboutBoardsIcon{
    text-decoration: none;
    color: white;
    transition: 0.7s;
    margin: 0 10px;
}

.AboutBoardsIcon:hover{
    color: #FBCC7A;
}

@media screen and (max-width: 600px) {    
    .AboutBoardsHeading{
        margin-bottom: 0.5em;
    }
    
    .AboutBoardsDesc{
        max-width: 90vw;
        font-size: 1em;
    }

    .AboutBoardsMember{
        margin: 1vh 5vw 0vh;
    }
}