.Memberdetails-wrapper{
    width: 80%;
    background-image: url("https://images.pexels.com/photos/1268855/pexels-photo-1268855.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1");
    background-position: center;
    background-size: cover;
}
.Memberdetails{
    width: 100%;
    display: flex;
    flex-direction: row;
    background: rgba(27, 27, 27, 0.7);
    /* background-color: rgb(185, 99, 99); */
    padding: 61px 0px;
    margin: 100px 0px;
}

.Mdetails-left{
    display: flex;
    flex-direction: column;
    width: 55%;
    align-items: center;
    padding: 16px;
    justify-content: space-evenly;
}
.Mdetails-card{
    display: flex;
    flex-direction: row;
    background: #ffffff38;
    /* background: #00000038; */
    width: 340px;
    padding: 10px;
    border-radius: 10px;
    margin: 16px 0px;
    position: relative;
}
.Mdetails-Sno{
    width: 38px;
    /* width: 68px; */
    height: 38px;
    border-radius: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* position: relative; */
    position: absolute;
    left: -17px;
    top: 16px;
    /* bottom: -4px; */
}
.Mdetails{
    display: flex;
    flex-direction: column;
    margin: 10px 10px 10px 28px;
    /* position: relative; */
}
.Mdetails-title{
    margin-bottom: 10px;
    font-weight: 700;
    letter-spacing: 1.5px;
}
.Mdetilas-description{
    color: #ffffffb5;
}
.Mdetails-right{
    width: 42%;
}
.AppDetails-page{
    display: flex;
    flex-direction: column;
    /* width: 430px; */
    width: 92%;
    height: 530px;
    border-radius: 10px;
    background-color: white;
    align-items: center;
    position: relative;
    right: -34%;
    box-shadow: -5px 6px 17px black;
}
.Adetails-title{
    color: #FBCC7A;
    margin: 32px 0px 21px 0px;
}
.Adetails-heading{
    color: #1c1c1c;
    font-size: 26px;
    font-weight: 700;
    margin: 0px 52px;
    text-align: center;
}
.Adetails-image>img{
    height: 180px;
    margin: 31px 0px;
}
.Adetails-btn{
    width: 116px;
    height: 40px;
    color: white;
    text-decoration: none;
    background: #fbcc7a;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    font-size: 18px;
    padding: 0px 14px;
    box-shadow: 4px 3px 8px goldenrod
}
@media screen and (max-width: 1000px) {
    .Memberdetails{
        padding: unset;
    }
    .Mdetails{
        margin: 10px 10px 3px 24px;
    }
    .Mdetails-card{
        width: 256px;
    }
    /* .Mdetails-Sno{
        width: 80px;
        height: 34px;
    } */
    .Mdetails-title{
        margin-bottom: 6px;
    }
    .Mdetails-right{
        display: flex;
        align-items: center;
        width: 48%;
    }
    .AppDetails-page{
        height: 400px;
        width: 100%;
    }
    .Adetails-title{
        margin: 18px 0px 8px 0px;
    }
    .Adetails-heading{
        font-size: 16px;
        font-weight: bold;
        margin: 0px 24px;
    }
    .Adetails-btn{
        width: 91px;
        height: 36px;
        box-shadow: 4px 3px 9px bisque;
        font-size: 16px;
    }
}

@media screen and (max-width: 600px) {
    .Memberdetails-wrapper{
        width: 88%;
        }
    .Memberdetails{
        flex-direction: column-reverse;
        /* height: 600px; */
        margin-top: 332px;
    }
    .Mdetails-left{
        width: 100%;
        padding: 0px;
        margin-bottom: 16px;
        margin-left: 10px;
    }
    .Mdetails-right{
        width: 90%;
        height: 200px;
    }
    .AppDetails-page{
        right: -16%;
        bottom: 270px;
        margin-top: 224px;
    }
}
