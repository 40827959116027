.about-company-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}
.ac-color{
    margin-top: 6rem;
    background-color: var(--bg-color-type3);

}

.about-company {
    width: var(--content-width);
    /* padding: 0px 24px; */
}

.ac-top-sec {
    /* padding: 2rem 5rem; */
    padding: 2rem 7vw;
}

.ac-info-cards {
    display: flex;
    justify-content: center;
    margin: 3rem 0rem;
}

.ac-top-para {
    font-size: 18px;
    text-align: center;
    margin-bottom: 2rem;
    font-family: var(--family2);
}

.ac-bottom-sec {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.review-cards {
    display: flex;
    justify-content: center;
    /* padding: 0rem 8rem; */
    margin: 5rem 0rem;
    width: 80%;
}
@media screen and (max-width:1024px) {
    .about-company-wrapper{
        margin-top: 3rem;
    }
    .ac-top-sec {
        padding: 2rem 4vw;
    }
    .review-cards{
        margin: 3rem 0rem;
    }
}

@media screen and (max-width:600px) {
    .about-company-wrapper {
        margin-top: 2rem;
    }
    .about-company {
        width: 100%;
    }
    .ac-top-sec{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding: 1rem 7vw;
    }
    .ac-info-cards {
        margin: 1.5rem 0rem;
        width: 90%;
    }
    .ac-top-para {
        font-size: 10px;
        margin-bottom: 0.5rem;
    }
    .ac-bottom-sec .underline-heading{
        font-size: 16px;
    }
    .review-cards {
        margin: 2rem 0rem;
    }
}