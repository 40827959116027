.SiteMap{
    display: flex;
    justify-content: center;
    margin: 0px 24px;
}
.SiteMap-Wrapper{
    width: var(--content-width);
    height: 100%;
    margin-top: 6rem;
    background-color: var(--bg-color-type3);
    padding: 24px;
    border-radius: 8px;
}
.title{
    color: var(--text-color-type8);
    padding-bottom: 14px;
    border-bottom: 2px solid var(--bg-color-type9);
}
.sitemp-1{
    padding: 28px 8px;
}
.sitemp-1 h3{
    color: var(--text-color-type8);
    margin-bottom: 4px;
}
.site-links{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.link-div{
    display: flex;
    align-items: center;
}

.site-link{
    color: var(--text-color-type6);
    font-size: 18px;
    padding: 4px;
    text-decoration: none;
}
.site-link:hover{
    color: var(--text-color-type1);
}
.divider{
    width: 2px;
    background: aliceblue;
    height: 18px;
    margin: 0px 6px;
    border-radius: 2px;
}
.site-links .link-div:last-child .divider {
    display: none;
  }