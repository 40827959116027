.package-card {
    /* margin: 24px 46px; */
    /* margin-left: 0rem; */
    cursor: pointer;
  }
  .package-card-image {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    /* width: 13.5rem; */
    /* width: 15rem; */
    width: 17.2vw;
    height: 17rem;
  }
  .package-detail {
    position: absolute;
    bottom: 0;
    display: flex;
    background-color: #0000008c;
    width: 93%;
    justify-content: space-between;
    padding: 5px 10px;
    align-items: center;
  }
  .package-name {
    font-weight: 700;
    font-size: 1.1rem;
  }
  .Blog_Search_Pagination {
    width: 25%;
  }
  @media screen and (max-width: 1024px) {
    .packages-type {
      padding: 0rem 1.5rem;
    }
    .package-card-image {
      /* width: 13.5rem; */
      width: 26vw;
    }
  }
  @media screen and (max-width: 600px) {
    /************** package-Cards ********/
    .package-card {
      margin: 1.5rem 0rem;
    }
    .package-card-image {
      width: 100%;
      height: 26rem;
    }
    .package-detail {
      width: 95%;
      padding: 16px 10px;
    }
    .package-name {
      font-size: 1.9rem;
    }
    .package-wishlist {
      font-size: 4rem;
      margin: 0rem 1rem;
    }
    .Blog_Search_Pagination {
      width: auto;
    }
  }
  