.subscribe{
    display: flex;
    width: 100%;
    justify-content: flex-end;
    position: relative;
}
.subscribe input{
    border: 0;
    outline: none;
    padding: 16px 20px;
    border-radius: 32px;
    width: 68%;
    color: var(--text-color-type5);
    font-size: 20px;
}

.subscribe input::placeholder{
    color: var(--text-color-type7);
}
 
.subscribe .redbtn-type2{
    width: 12%;
    position: absolute;
    height: 100%;
    bottom: 0;
    border-radius: 32px;
    font-size: 20px;
}

@media screen and (max-width:1024px) {
    .subscribe input{
        width: 80%;
        padding: 12px 20px;
    }
    .subscribe .redbtn-type2{
        width: 22%;
    }
}
@media screen and (max-width:600px) {
    .subscribe input{
        padding: 6px 12px;
        font-size: 12px;
        width: 92%;
    }
    .subscribe .redbtn-type2{
        width: 28%;
        font-size: 12px;
    }
}