.upper-heading{
    font-size: 44px;
    margin: 0;
    font-weight: var(--medium);
    margin-bottom: 4px;
}
.upper-heading span{
    color: var(--text-color-type2);
}
.lower-para{
    font-size: 18px;
    font-family: var(--family2);
}

@media screen and (max-width:1024px) {
    .upper-heading{
        font-size: 34px;
    }
}

@media screen and (max-width:600px) {
    .upper-heading{
        font-size: 24px;
    }
    .lower-para {
        font-size: 12px;
    }
}