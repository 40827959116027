.slider {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.slider-main {
    display: flex;
    align-items: center;
    width: 100%;
}

.slider-image-title {
    font-size: 2.5vw;
    text-align: center;
    color: #1F1D36;
    margin: 1.5rem 2rem;
}

.Larrow {
    font-size: 40px;
    color: #C6D1FF;
    margin-right: 20px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(0, 50%);
    cursor: pointer;
}

.Rarrow {
    font-size: 40px;
    color: #C6D1FF;
    margin-left: 20px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(0, 50%);
    cursor: pointer;
}

.Larrow .sgn-arrow-icon,
.Rarrow .sgn-arrow-icon {
    font-size: 42px;
}

.dotContainer {
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
}

.dot {
    margin: 5px;
    cursor: pointer;
    background-color: #D9D9D9;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    transition: all 0.5s ease-out;
}

.active-dot {
    background-color: #E94560;
    margin: 5px;
    border-radius: 20px;
    width: 25px;
    height: 10px;
    transition: all 0.5s ease-out;
    cursor: pointer;
}

@media screen and (max-width:600px) {
    .slider {
        position: relative;
        width: 100%;
    }

    .slider-main {
        width: auto;
    }

    .slider-image-title {
        font-size: 18px;
        margin: 0rem 0rem 1rem 0rem;
    }

    .dotContainer {
        margin-top: 0;
    }

    .Rarrow,
    .Larrow {
        margin: 0;
    }

    .Larrow .sgn-arrow-icon,
    .Rarrow .sgn-arrow-icon {
        font-size: 28px;
    }
}