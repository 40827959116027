.search-page-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}

.search-page {
    width: 100%;
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    align-items: center;
}

.search-header {
    background-color: #1F1D3680;
    width: 100%;
    height: 32vh;
    display: flex;
    align-items: center;
    padding-top: 4rem;
}

.searchbar-filter {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: inherit;
}

.search-header .search-card-wrapper .search-card {
    width: 86vw;
}

.mob-filter {
    display: none;
}

.search-body {
    max-width: calc(1280px - 48px);
    margin: 0px 24px;
}

.search-body-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 4rem 0rem 6rem 0rem;

}

.search-filter-sec {
    width: 25%
}

.search-result {
    width: 44%;

}

.seacrh-most-visited-content {
    width: 25%;
}

/* .search-filter-sec{

}
.search-result{

}
.seacrh-body-right-content{

} */
.search-footer {
    width: 100%;
}

@media screen and (max-width:1024px) {
    .searchbar-filter .search-card-wrapper{
        width: 76%;
    }
    .mob-filter {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 64px;
        background-color: var(--btn-color-type8);
        padding: 2px 8px;
        border-radius: 28px;
        height: 18%;
        margin-right: 16px;
        /* height: auto; */
        margin-bottom: 3rem;
        cursor: pointer;
    }

    .mob-filter .mob-filter-icon {
        color: var(--text-color-type1);
        font-size: 28px;
        cursor: pointer;
    }

    .search-body-content {
        margin: 2rem 0rem 3rem 0rem;
    }

    .search-result {
        width: 60%;
    }

    .search-filter-sec {
        display: none;
    }

    .seacrh-most-visited-content {
        width: 36%;
    }

     /* Mobile -search filter Pop Up */
     .mob-search-filter-popup {
        /* display: flex; */
        width: 100%;
        z-index: 1;
        /* position: absolute; */
        padding: 2rem 8px 4rem;
        z-index: 9999;
        background-color: #1F1D36f2;
        display: flex;
        flex-direction: column;
        align-items: center;

        position: fixed;
        height: 93vh;
    }

    .mob-search-filter-popup .search-filter {
        padding: 0px 10px;
        margin-top: 3rem;
        transform: translateY(1%);
        transition: 0.5s ease-in-out;
        width: 80%;
        height: 80%;

        overflow-y: scroll;
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
    }

    .mob-search-filter-popup .search-filter::-webkit-scrollbar {
        display: none;
    }

    .filter-close {
        display: flex;
        justify-content: flex-end;
        margin-right: 10px;
        width: 80%;
    }

    .filter-close-icon {
        background: #ffffff2e;
        color: #e94560;
        padding: 4px;
        border-radius: 100%;
        font-size: 28px;
    }
}

@media screen and (max-width:600px) {
    .search-header .search-card-wrapper {
        width: 82%;
    }

    .search-header .search-card-wrapper .search-card {
        width: 76vw;
    }
    .mob-filter{
        width: 36px;
        padding: 2px 8px;
        border-radius: 20px;
        margin-bottom: 0;
        height: 24%;
    }
    .mob-filter .mob-filter-icon{
        font-size: 20px;
    }
    .search-body {
        width: 96%;
        margin: 0;
    }

    .search-body-content {
        flex-direction: column;
        margin: 1rem 0rem;
    }

    .search-filter-sec {
        width: 100%;
        display: none;
    }

    .search-header {
        height: 18vh;
        justify-content: center;
    }

    .search-header .search-card-wrapper {
        margin: 0;
    }

    .search-result {
        width: 100%;

    }

    .seacrh-most-visited-content {
        display: none;
    }

    /* Mobile -search filter Pop Up */
    /* .mob-search-filter-popup {
        width: 96%;
        z-index: 1;
        padding: 2rem 8px 4rem;
        z-index: 9999;
        background-color: #1F1D36f2;
        display: flex;
        flex-direction: column;
        align-items: center;

        position: fixed;
        height: 90vh;
    }

    .mob-search-filter-popup .search-filter {
        padding: 0px 10px;
        margin-top: 3rem;
        transform: translateY(1%);
        transition: 0.5s ease-in-out;
        width: 80%;
        height: 80%;

        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .mob-search-filter-popup .search-filter::-webkit-scrollbar {
        display: none;
    }

    .filter-close {
        display: flex;
        justify-content: flex-end;
        margin-right: 10px;
        width: 80%;
    }

    .filter-close-icon {
        background: #ffffff2e;
        color: #e94560;
        padding: 4px;
        border-radius: 100%;
        font-size: 28px;
    } */
}