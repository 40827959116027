.whilist-wrapper{
    padding-top: 100px;
    margin: 0px 72px;
}
.whislist-top{
    background-color: #1c1c1c;
    padding: 36px 28px;
    border-radius: 8px;
    margin: 16px 0px;
}
.whislist-top #wTp1{
    color: #fbcc7a;
    margin-bottom: 16px;
    font-size: 34px;
}
.whislist-top #wTp2{
    color: #989898; 
    font-size: 18px;
}
.addMore-btn{
    background-color: #fbcc7a;
    width: 104px;
    padding: 8px 12px;
    color: #1c1c1c;
    font-weight: bolder;
    font-size: 18px;
    margin-top: 14px;
    border-radius: 22px;
    display: flex;
    justify-content: center;
    text-decoration: none;
}

@media screen and (max-width: 600px) {
    .whilist-wrapper{
        margin: 0px 18px;
    }
}