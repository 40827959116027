.blog-banner-wrapper {
  background-image: linear-gradient(135deg, var(--Dark-Blue-Primary), #2d2865);
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 7rem 0rem 5rem 0rem;
}
.blog-banner-body {
  width: var(--content-width);
  padding: 0px 24px;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.blog-banner-heading {
  font-size: var(--Display-L);
  margin-bottom: 0.5rem;
}

.blog-banner-sub-heading {
  font-size: var(--Heading-S);
}

.blog-banner-search-bar {
  display: flex;
  width: 50%;
  margin-top: 2rem;
}
.handleClick {
  display: flex;
  width: 50%;
}
.blog-banner-search-bar .blog-input-wrapper {
  margin-right: 5rem;
}
.handleClick .blog-btn {
  width: fit-content;
}

@media screen and (max-width: 600px) {
  .blog-banner-wrapper {
    padding: 7rem 0rem 3rem 0rem;
  }
  .blog-banner-body {
    padding: 0px 16px;
  }
  .blog-banner-heading {
    font-size: 28px;
  }
  .blog-banner-sub-heading {
    font-size: 15px;
  }
  .blog-banner-search-bar {
    flex-direction: column;
    width: auto;
    margin-top: 1.25rem;
    /* align-items: center; */
  }
  .blog-banner-search-bar .blog-input-wrapper .search-input {
    width: 76%;
  }
  .handleClick {
    justify-content: center;
    width: 100%;
  }
  .handleClick .blog-btn {
    align-self: center;
    border-radius: 9px;
    height: 44px;
    width: 174px;
    margin-top: 22px;
    font-size: 16px;
  }
}
