.copyright {
  margin-top: 15px;
  width: 100%;
  height: 50px;
  background-color: var(--Dark-Blue-Primary);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #989898;
}

@media screen and (max-width: 600px) {
  .copyright {
    height: 36px;
  }
}
