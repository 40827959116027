.login-page-wrapper{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}
.login-page{
    width: var(--content-width);
    display: flex;
    align-items: center;
    justify-content: center;
    /* height: 84vh; */
    height: 80%;
    margin: 0rem 6rem;
}
.lp-left{
    width: 42%;
    background-color: #E3E8FF;
    border-radius: 20px 0px 0px 20px;
    /* height: -webkit-fill-available; */
    height: 100%;
    /* padding: 6rem 0rem; */
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0rem 0rem 0rem 6.8vw;
}
.images{
    width: 92%;
    display: flex;
    justify-content: space-around;
    height: 66.2%;
    position: absolute;
    right: -32px;
}
.lp-left .image{
    height: 100%;
    margin: 0rem 0.5rem;
}
.lp-right{
    width: 34.5%;
    background-image: linear-gradient(to bottom right,#373D5F, #1F1D36) ;
    display: flex;
    flex-direction: column;
    border-radius: 0px 20px 20px 0px;
    height: 100%;
    /* padding: 0rem 6rem; */
    padding: 0vw 6.8vw;
    justify-content: center;

}
.login-heading{
    font-size: 3vw;
    color: #c6d1ff;
    /* margin-top: 5rem; */
}
.login-sub-heading{
    font-size: 1.75vw;
    color: #c6d1ff;
    margin-bottom: 1.5rem;
}
.lp-right .login-username, .login-password{
    margin: 0.5rem 0rem;
}
.forget-password{
    text-align: end;
    color: #C6D1FF;
    cursor: pointer;
}
.lgn-btn{
    border: 0;
    color: var(--text-color-type8);
    background-color: var(--btn-color-type1);
    padding: 6px 0px;
    border-radius: 8px;
    font-size: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem 0rem;
}

.login-singup-link{
    color: #C6D1FF;
    text-align: center;
}
.lg-signup-link{
    color: var(--text-color-type1);
    cursor: pointer;
    text-decoration: none;
}
@media only screen and (orientation: portrait) {
    .login-page{
        height: 48vh;
    }
}
@media screen and (max-width:1024px){
    .login-page{
        margin: 0rem 2rem;
        /* height: 48vh; */
    } 
    .lp-left {
        width: 44%;
        /* padding: 6rem 0rem 6rem 4.8vw; */
        padding: 0rem 0rem 0rem 4.8vw;
    }
    .images{
        right: -12px;
    }
    .lp-right {
        width: 40.5%;
    }
}

@media screen and (max-width:600px){
    .login-page-wrapper{
        background-image: linear-gradient(to bottom right,#373E60, #211F39) ;

    }
    .login-page{
        margin: 0rem 1.5rem;
        flex-direction: column;
        height: 80%;
    } 
    .lp-left{
        width: 95%;
        border-radius: 20px;
        position: unset;
        padding: 8px;
    }
    .images{
        position: unset;
        width: 90%;
        /* height: 80%; */
        justify-content: center;
    }
    .lp-right{
        width: 100%;
        background-image: none;
        padding: unset;
    }  
    .login-heading{
        font-size: 24px;
        margin-top: 2rem;
    }
    .login-sub-heading{
        font-size: 16px;
    }
    .lgn-btn{
        margin: 1.5rem 0rem;
        border-radius: 6px;
    }
}
@media only screen and (orientation: landscape ) and (max-height:600px){
    .login-heading {
        margin-top: 2rem;
    }
    .lp-right .login-username, .login-password{
        margin: 0.3rem 0rem;
    }
    .lgn-btn{
        margin: 0.8rem 0rem;
    }
}

