.package-wrapper {
    padding: 94px 24px 0px 24px;
  }
  
  .package-banner {
    background-image: linear-gradient(151.17deg, #12100e, #122239);
    border-radius: 10px;
    padding: 2rem 3.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .package-info {
    width: 40%;
  }
  .package-banner .title-1 {
    color: #55cdec;
    font-size: 1.3rem;
  }
  .package-banner .title-2 {
    color: #fbcc7a;
    font-size: 2.5rem;
    margin-top: 6px;
    /* z-index: 1; */
  }
  .package-search-icon .P-search-icon {
    font-size: 12rem;
    color: #979292;
    opacity: 0.2;
    position: relative;
    top: -33px;
    right: 16px;
  }
  .package-promoted-cards {
    display: flex;
    justify-content: center;
    /* width: 64%; */
    width: 52%;
  }
  .package-promoted-cards button {
    display: none;
  }
  .packages-type {
    margin-top: 5rem;
    padding: 0rem 3.5rem;
  }
  .package-title {
    font-size: 1.3rem;
    font-weight: 600;
  }
  /* /////// PlacesPackage --css //////////////////*/
  .package-cards {
    margin-top: 1.5rem;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    /* flex-wrap: wrap; */
  }
  
  .r1,
  .r2 {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .r1 .P-Card,
  .r2 .P-Card {
    margin: 24px 46px;
    margin-left: 0px;
  }
  .P-Card:nth-last-child(1) {
    margin-right: 0px;
  }
  /*////////////////////////// Mobile-view-css////// */
  @media screen and (max-width: 1024px) {
    .package-banner {
      padding: 2rem 1.5rem;
    }
    .package-cards {
      margin-top: 0.5rem;
    }
    .r1 .P-Card,
    .r2 .P-Card {
      margin: 24px 13px;
      margin-left: 0px;
    }
    .P-Card:nth-last-child(1) {
      margin-right: 0px;
    }
  }
  @media screen and (max-width: 600px) {
    .package-wrapper {
      padding: 58px 0px 0px 0px;
    }
    .package-banner {
      padding: 0;
      justify-content: center;
      flex-direction: column;
      border-radius: 0px;
    }
    .package-info {
      margin: 1.5rem 0rem;
      width: auto;
      padding: 0% 5%;
    }
    .package-banner .title-2 {
      font-size: 1.8rem;
    }
    .package-search-icon .P-search-icon {
      display: none;
    }
    .packages-type {
      padding: 0rem 1.5rem;
      margin-top: 2rem;
    }
    .package-title {
      font-size: 1.5rem;
    }
    .package-cards {
      margin-top: 0.5rem;
      flex-direction: column;
    }
    .r1,
    .r2 {
      flex-direction: column;
    }
    .r1 .qwert,
    .r2 .qwert {
      margin: 0px;
    }
    .Blog_Search_Pagination {
      width: auto;
    }
  }
  
  /*///////////////////////////////// pagination-css /////////////////////////////*/
  .Blog_Search_Pagination nav {
    color: white;
  }
  
  .css-g2z002-MuiSvgIcon-root-MuiPaginationItem-icon {
    color: #ffcc7a;
    font-size: 2rem;
  }
  .pagination ul li button {
    color: #fff;
  }
  
  .pagination ul li button.Mui-selected {
    background-color: #ffcc7a;
    color: #000;
  }
  .pagination ul li button.Mui-selected:hover {
    background-color: #ffcc7a;
    color: #000;
  }
  